<script setup>
import { useI18n } from '@/util';
import { useFormulaAdvancedOptions } from './useFormulaAdvancedOptions';

defineProps({
  isFormulaValid: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const showAdvancedOptions = shallowRef(false);

const modelValue = defineModel({ type: Object, required: true });

const { formats, showSymbol, showDecimals, decimalOptions, currencies, getCurrencyTitle } = useFormulaAdvancedOptions();
</script>

<template>
  <div class="py-2">
    <LscButton variant="plain-secondary" size="md" @click="showAdvancedOptions = !showAdvancedOptions">
      <template #prepend>
        <LscIcon icon="lsi-dropdown" :class="showAdvancedOptions && 'rotate-180'" />
      </template>
      {{ t('Advanced options') }}
    </LscButton>
    <div v-if="showAdvancedOptions" class="mt-6 flex flex-col gap-4">
      <div class="inline-flex w-full items-center gap-4">
        <VSelect
          v-model="modelValue.format"
          :label="t('Format')"
          :items="formats"
          :disabled="!isFormulaValid"
          class="w-36"
        />

        <VSelect
          v-if="showSymbol(modelValue.format)"
          v-model="modelValue.symbol"
          :label="t('Symbol')"
          :items="currencies"
          :itemTitle="getCurrencyTitle"
          :returnObject="true"
          :disabled="!isFormulaValid"
          class="w-36"
        />
        <VSelect
          v-if="showDecimals(modelValue.format)"
          v-model="modelValue.decimals"
          :label="t('Decimals')"
          :items="decimalOptions"
          :disabled="!isFormulaValid"
          placeholder="0"
          persistent-placeholder
          class="w-16"
        />
        <LscIcon
          v-if="!isFormulaValid"
          v-LsdTooltip="t('The format can be set once a valid formula is composed')"
          icon="lsi-tooltip"
          class="-ml-2 text-icon-subtle"
        />
      </div>
      <VTextarea v-model.trim="modelValue.description" :label="t('Add description to the formula')" :rows="4" />
    </div>
  </div>
</template>
