<script setup>
import { useCustomfieldV3Loader } from '@/api';
import { useI18n } from '@/util';
import { CustomfieldAddOrEditDialogPanel, customfieldTypes, useCustomfieldTracking } from '@/module/customfield';

const props = defineProps({
  customfieldId: {
    type: Number,
    default: null,
  },

  modelValue: {
    type: Boolean,
    default: false,
  },

  entity: {
    type: String,
    default: 'task',
  },

  canAddFormula: {
    type: Boolean,
    default: false,
  },

  addingFormula: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue', 'created']);
const { t } = useI18n();
const toast = useLsToast();
const { trackCustomfieldAddedViaCustomReport } = useCustomfieldTracking();
const defaultCustomFieldType = computed(() => (props.addingFormula ? customfieldTypes.FORMULA : customfieldTypes.TEXT));

const { item, inSync } = useCustomfieldV3Loader({
  customfieldId: computed(() => props.customfieldId),
  params: { getCount: true },
});

const modelValue = computed({
  get: () => {
    return props.modelValue ? props.customfieldId === null || (props.customfieldId && inSync.value) : null;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

function handleUpdated() {
  toast.success(t('Custom field updated successfully'));
}

function handleCreated(...args) {
  trackCustomfieldAddedViaCustomReport();
  toast.success(t('Custom field created'));
  emit('created', ...args);
}
</script>

<template>
  <LscDialog v-model="modelValue">
    <CustomfieldAddOrEditDialogPanel
      :entity="entity"
      :customfield="item"
      :canAddFormula="canAddFormula"
      :defaultCustomFieldType="defaultCustomFieldType"
      showInfoAlert
      @close="modelValue = false"
      @created="handleCreated"
      @deleted="handleUpdated"
      @updated="handleUpdated"
    />
  </LscDialog>
</template>
