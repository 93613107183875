<script setup>
import { useI18n } from '@/util';

defineProps({
  /**
   * This icon will be rendered before the button's content.
   * @type {PropType<LscIconName>}
   */
  prependIcon: {
    type: String,
    default: undefined,
  },
  /**
   * This icon will be rendered after the button's content.
   * @type {PropType<LscIconName>}
   */
  appendIcon: {
    type: String,
    default: undefined,
  },
  /**
   * Shows a loading indicator inside the button.
   */
  loading: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();

const alertButtonVariantStyleConfig = tv({
  base: [
    'relative flex items-center gap-1 whitespace-nowrap rounded-full',
    'text-button-1 font-semibold text-default',
    'px-[--lsds-c-button-spacing-outer-md]',
    'h-[--lsds-c-button-size-md]',
    'gap-[--lsds-c-button-spacing-gap-md]',
    'bg-[--lsds-c-alert-button-color-bg-default] hover:bg-[--lsds-c-alert-button-color-bg-hover]',
  ],
  slots: {
    loadingIcon: [
      'shrink-0 animate-spin rounded-full border-2 border-current border-t-transparent',
      'size-[--lsds-a-icon-size-md]',
    ],
  },
});

const classes = computed(() => alertButtonVariantStyleConfig());
</script>

<template>
  <button type="button" :class="classes.base()" :disabled="loading">
    <div :class="{ invisible: loading }" class="contents">
      <slot name="prepend">
        <LscIcon v-if="prependIcon" :icon="prependIcon" size="sm" />
      </slot>
      <slot />
      <slot name="append">
        <LscIcon v-if="appendIcon" :icon="appendIcon" size="sm" />
      </slot>
    </div>
    <div v-if="loading" class="absolute inset-0 flex items-center justify-center">
      <span :class="classes.loadingIcon()" role="status" :aria-label="t('Loading')">
        <span class="sr-only">{{ t('Loading') }}</span>
      </span>
    </div>
  </button>
</template>
