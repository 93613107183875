export function formatEmbeddableLink(link) {
  let string = link;

  if (string.includes('iframe') && string.includes('src=')) {
    // Pull out the valid src url and replace surrounding quotes
    string = string.split('src=')[1].split(/[ >]/)[0];
    string = string.replace(/^"(.+(?="$))"$/, '$1');
  }

  // YouTube
  const youtubeUrlRegex =
    /(?:(?:http(?:s)?:\/\/)?(?:www.|m.)?(?:youtu\.be\/|youtube\.com(?:\/e\/|\/watch\/|\/embed\/|\/v\/|\/watch\?v=|\/.*[?&]v=|v\/))([\w-]{11}))/;
  if (youtubeUrlRegex.test(string)) {
    return string.replace(youtubeUrlRegex, 'https://www.youtube.com/embed/$1');
  }

  // Google Drive
  const googleDriveUrlRegex = /(https:\/\/drive\.google\.com\/file\/d\/[a-zA-Z0-9_-]+)\/view/;
  if (googleDriveUrlRegex.test(link)) {
    return string.replace(googleDriveUrlRegex, '$1/preview');
  }

  // Figma
  if (string.includes('figma')) {
    // If a user pastes the figma URL directly into our tool, we can simply reformat the URL to embed it
    // Replace file/ with: embed?embed_host=share&url=https://www.figma.com/file/
    // We also handle the case where they clicked 'presentation mode' with proto/ URL
    if (string.includes('proto/')) {
      string = string.replace('proto/', 'embed?embed_host=share&url=https://www.figma.com/file/');
    } else {
      string = string.replace('file/', 'embed?embed_host=share&url=https://www.figma.com/file/');
    }
  }

  // SharePoint
  const sharepointRegex = /^https:\/\/([^-]+-my\.sharepoint\.com)\/:(?:[^:]+):\/g\/personal\/([^/]+)\/([^/?]+)/;
  const sharepointMatch = string.match(sharepointRegex);
  if (sharepointMatch) {
    const [, host, user, fileID] = sharepointMatch;
    return `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
      `https://${host}/personal/${user}/_layouts/15/download.aspx?share=${fileID}`,
    )}`;
  }

  // Canva
  const canvaUrlRegex = /https:\/\/www\.canva\.com\/design\/(?:[a-zA-Z0-9_-]+\/)+view/;
  const canvaMatch = string.match(canvaUrlRegex);
  if (canvaMatch) {
    return `${canvaMatch[0]}?embed`;
  }

  return string;
}
