<script setup>
import { provideCustomTableColumns } from '../table/useCustomTableColumns';
import ReportBuilderDialogPanel from './ReportCustomBuilderDialogPanel.vue';
import { provideReportCustomBuilderFilters } from './useReportCustomBuilderFilters.js';

defineProps({
  report: {
    type: Object,
    default: () => ({}),
  },
  copyingReport: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['updated', 'handleCustomReportTrack']);

provideCustomTableColumns();
provideReportCustomBuilderFilters();
provideCustomTableColumns();

function handleCustomReportTrack(pageType, eventAction, eventCategory, ...args) {
  emit('handleCustomReportTrack', pageType, eventAction, eventCategory, ...args);
}
</script>

<template>
  <LscDialog fullscreen :retainFocus="false">
    <template #activator="activator"><slot v-bind="activator" name="activator" /></template>
    <template #default="{ close }">
      <ReportBuilderDialogPanel
        :report="report"
        :copyingReport="copyingReport"
        @close="close"
        @updated="emit('updated')"
        @handleCustomReportTrack="handleCustomReportTrack"
      />
    </template>
  </LscDialog>
</template>
