const targets = new WeakMap();

// Check the overflow of the element including subpixel values
function checkOverflowing(element) {
  if (element.offsetWidth !== element.scrollWidth) {
    return element.scrollWidth > element.offsetWidth;
  }
  const range = document.createRange();
  range.selectNodeContents(element);

  const rangeWidth = range.getBoundingClientRect().width;
  const containerWidth = element.getBoundingClientRect().width;

  return rangeWidth > containerWidth;
}

const observer = new ResizeObserver((entries) => {
  for (const entry of entries) {
    const target = targets.get(entry.target);
    if (target) {
      target.value = checkOverflowing(entry.target);
    }
  }
});

export function useOverflowEllipsis(ref) {
  const isOverflowing = shallowRef(false);

  function unregisterElement(element) {
    targets.delete(element);
    if (element) {
      observer.unobserve(element);
    }
  }

  function registerElement(element) {
    targets.set(element, isOverflowing);
    observer.observe(element);
  }

  watch(
    ref,
    (newElement, oldElement) => {
      if (oldElement) {
        unregisterElement(oldElement);
      }
      if (newElement) {
        registerElement(newElement);
      }
    },
    { immediate: true },
  );

  onUnmounted(() => {
    unregisterElement(ref.value);
  });

  return isOverflowing;
}
