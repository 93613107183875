import { DateTime } from 'luxon';
import { useI18n } from '@/util';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function toApiProject(project) {
  const apiProject = {};
  apiProject.id = project.id;
  if (Object.hasOwn(project, 'description')) {
    apiProject.description = project.description;
  }

  if (Object.hasOwn(project, 'name')) {
    apiProject.name = project.name;
  }

  if (Object.hasOwn(project, 'people')) {
    apiProject.people = project.people;
  }

  if (Object.hasOwn(project, 'startDate')) {
    apiProject.startDate = DateTime.isDateTime(project.startDate)
      ? project.startDate.toFormat('yyyyMMdd')
      : project.startDate;
  }

  if (Object.hasOwn(project, 'endDate')) {
    apiProject.endDate = DateTime.isDateTime(project.endDate) ? project.endDate.toFormat('yyyyMMdd') : project.endDate;
  }

  if (Object.hasOwn(project, 'logoIcon')) {
    apiProject.logoIcon = project.logoIcon;
  }

  if (Object.hasOwn(project, 'logoColor')) {
    apiProject.logoColor = project.logoColor;
  }

  if (Object.hasOwn(project, 'ignoreConstraints')) {
    apiProject.ignoreConstraints = project.ignoreConstraints;
  }

  if (Object.hasOwn(project, 'categoryId')) {
    apiProject['category-id'] = Number(project.categoryId);
  } else if (Object.hasOwn(project, 'category')) {
    apiProject['category-id'] = Number(project.category.id);
  }
  if (Object.hasOwn(project, 'customFieldValues')) {
    apiProject.customFields = project.customFieldValues.map(({ customFieldId, value, urlTextToDisplay }) => ({
      customFieldId,
      value,
      urlTextToDisplay,
    }));
  }
  if (Object.hasOwn(project, 'tags')) {
    apiProject.tagIds = project.tags
      .map((tag) => {
        if (typeof tag === 'object' && tag.id !== undefined) {
          return tag.id;
        }
        return tag;
      })
      .join(',');
  }
  if (Object.hasOwn(project, 'projectOwnerId')) {
    apiProject.projectOwnerId = Number(project.projectOwnerId);
  }

  if (Object.hasOwn(project, 'companyId')) {
    apiProject.companyId = Number(project.companyId);
  }

  // Allow all "use-*" keys for toggling project features like tasks, notebooks, messages, etc.
  Object.entries(project).forEach(([key, value]) => {
    if (key.startsWith('use-')) {
      apiProject[key] = value;
    }
  });

  return apiProject;
}

function hasCategoryChange(updatedProject) {
  return Object.hasOwn(updatedProject, 'categoryId') || Object.hasOwn(updatedProject, 'category');
}

/**
 * Loads the specificed project.
 */
export function useProjectActions() {
  const { t } = useI18n();
  const api = useAxios();
  const { socketId, emit: _emitRealTimeUpdate } = useRealTimeUpdates();
  const { emit: _emitOptimisticUpdate } = useOptimisticUpdates();

  /**
   *
   * @param {Promise} promise
   * @param {'create'|'update'|'delete'} action
   * @param {object} project
   */
  function emitOptimisticUpdate(promise, action, project) {
    _emitOptimisticUpdate({
      promise,
      type: 'project',
      action,
      project,
    });
  }

  /**
   *
   * @param {string} action
   * @param {object} project
   */
  function emitRealTimeUpdate(action, project) {
    _emitRealTimeUpdate({
      type: 'project',
      action,
      projectId: project.id,
      categoryChanged: hasCategoryChange(project),
    });
  }

  function cloneProject(project) {
    const promise = api
      .post(`/projects/${project.id}/clone.json`, project, {
        headers: { 'Socket-ID': socketId },
        errorMessage(error) {
          if (error.response?.status === 409) {
            return t('This project name is taken already');
          }
          return t('Failed to create project');
        },
      })
      .then((response) => {
        const clonedProject = response.data;
        emitRealTimeUpdate('added', clonedProject);
        return clonedProject;
      });

    emitOptimisticUpdate(promise, 'create', { ...project, id: -1 });
    return promise;
  }

  function createProject(project) {
    const promise = api
      .post(
        `/projects.json`,
        { project: toApiProject(project) },
        {
          headers: { 'Socket-ID': socketId },
          errorMessage(error) {
            if (error.response?.status === 409) {
              return t('This project name is taken already');
            }
            return t('Failed to create project');
          },
        },
      )
      .then((response) => {
        const createdProject = response.data;
        emitRealTimeUpdate('added', project);
        return createdProject;
      });
    emitOptimisticUpdate(promise, 'update', project);
    return promise;
  }

  function createProjectFromJSON(project) {
    const promise = api
      .post(
        `/projects/json.json`,
        { project, waitForResponse: true },
        {
          headers: { 'Socket-ID': socketId },
          errorMessage: t('Failed to create project'),
        },
      )
      .then((response) => {
        const createdProject = response.data;
        emitRealTimeUpdate('added', project);
        return createdProject;
      });
    emitOptimisticUpdate(promise, 'update', project);
    return promise;
  }

  function createTentativeProject(project) {
    const promise = api
      .post(
        '/projects/tentative.json',
        {
          project,
        },
        {
          headers: { 'Socket-ID': socketId },
          errorMessage(error) {
            if (error.response?.status === 409) {
              return t('This project name is taken already');
            }
            return t('Failed to create project');
          },
        },
      )
      .then((response) => {
        emitRealTimeUpdate('added', response.data);
        return response.data.id;
      });
    return promise;
  }

  function convertTentativeProjectToNormal(project) {
    const updatedProject = { ...project, type: 'normal' };
    const promise = api
      .put(
        `/projects/api/v3/projects/tentative/${updatedProject.id}/convert.json`,
        { project: toApiProject(updatedProject) },
        {
          headers: { 'Socket-ID': socketId },
          errorMessage: t('Failed to update project'),
        },
      )
      .then(() => {
        emitRealTimeUpdate('edited', updatedProject);
      });
    emitOptimisticUpdate(promise, 'update', updatedProject);
    return promise;
  }

  function deleteProject(project) {
    const promise = api
      .delete(`/projects/${project.id}.json`, null, {
        headers: { 'Socket-ID': socketId },
        errorMessage: t('Failed to delete project'),
      })
      .then(() => {
        emitRealTimeUpdate('deleted', project);
      });
    emitOptimisticUpdate(promise, 'delete', project);
    return promise;
  }

  function toggleStarProject(project) {
    const shouldStar = !project.isStarred;
    const promise = api
      .put(shouldStar ? `/projects/${project.id}/star.json` : `/projects/${project.id}/unstar.json`, null, {
        headers: { 'Socket-ID': socketId },
        errorMessage: shouldStar ? t('Failed to star project') : t('Failed to unstar project'),
      })
      .then(() => {
        emitRealTimeUpdate(shouldStar ? 'starred' : 'unstarred', project);
      });

    emitOptimisticUpdate(promise, 'update', { id: project.id, isStarred: shouldStar });
    return promise;
  }

  function toggleArchiveProject(project) {
    const shouldArchive = project.status === 'active';
    const promise = api
      .put(`/projects/${project.id}/archive.json`, shouldArchive ? { status: 'inactive' } : { status: 'active' }, {
        headers: { 'Socket-ID': socketId },
        errorMessage: shouldArchive ? t('Failed to archive project') : t('Failed to unarchive project'),
      })
      .then(() => {
        emitRealTimeUpdate(shouldArchive ? 'archived' : 'unarchived', project);
      });
    emitOptimisticUpdate(promise, 'update', { id: project.id, status: shouldArchive ? 'archived' : 'active' });
    return promise;
  }

  function toggleCompleteProject(project) {
    const shouldComplete = project.subStatus !== 'completed';
    const promise = api
      .put(
        shouldComplete ? `/projects/${project.id}/complete.json` : `/projects/${project.id}/uncomplete.json`,
        undefined,
        {
          headers: { 'Socket-ID': socketId },
          errorMessage: shouldComplete
            ? t('Failed to mark project as complete')
            : t('Failed to mark project as uncomplete'),
        },
      )
      .then(() => {
        emitRealTimeUpdate(shouldComplete ? 'completed' : 'uncompleted', project);
      });
    emitOptimisticUpdate(promise, 'update', { ...project, subStatus: shouldComplete ? 'completed' : 'current' });
    return promise;
  }

  function updateProject(updatedProject) {
    const promise = api
      .put(
        `/projects/${updatedProject.id}.json`,
        { project: toApiProject(updatedProject) },
        {
          headers: { 'Socket-ID': socketId },
          errorMessage(error) {
            if (error.response?.status === 409) {
              return t('This project name is taken already');
            }
            return t('Failed to update project');
          },
        },
      )
      .then(() => {
        emitRealTimeUpdate('edited', updatedProject);
      });
    emitOptimisticUpdate(promise, 'update', updatedProject);
    return promise;
  }

  /**
   * Update project email address
   * @param {number} projectId
   * @param {string} code
   */
  function updateProjectEmailAddress(projectId, code) {
    const promise = api
      .put(
        `/projects/${projectId}/emailaddress.json`,
        { emailaddress: { code } },
        {
          headers: { 'Socket-ID': socketId },
          errorMessage(error) {
            if (error.response.data?.MESSAGE === 'Nothing to modify') {
              return t('Nothing to modify');
            }
            if (error.response.data?.MESSAGE === 'That code is not available') {
              return t('That code is not available');
            }
            return t('Failed to update this email address');
          },
        },
      )
      .then(() => {
        emitRealTimeUpdate('edited', { id: projectId });
      });
    return promise;
  }

  /**
   * Update project feature orders
   * @param {number} projectId
   * @param {object} featureOrder
   */
  function updateProjectFeatureOrder(projectId, featureOrder) {
    return api.put(`/projects/api/v3/projects/${projectId}/featureorder.json`, featureOrder, {
      headers: { 'Socket-ID': socketId },
      errorMessage: t('Failed to update project'),
    });
  }

  /**
   * Update project tags
   * @param {{id: number}} project
   * @param {number[]} tagIds
   */
  function updateProjectTags(project, tags = []) {
    const promise = api
      .put(
        `/project/${project.id}/tags.json`,
        { tagIds: tags.map((tag) => tag.id).join(',') },
        {
          params: { replaceExistingTags: true },
          headers: { 'Socket-ID': socketId },
          errorMessage: t('Failed to update the tags'),
        },
      )
      .then(() => {
        emitRealTimeUpdate('edited', { id: project.id });
      });

    return promise;
  }

  /**
   * Update project users
   * @param {number} projectId
   * @param {number[]} userIds
   */
  function updateProjectUsers({ projectId, userIds }) {
    return api
      .put(
        `/projects/api/v3/projects/${projectId}/people.json`,
        { userIds },
        {
          headers: { 'Socket-ID': socketId },
          errorMessage: t('Failed to add users to project'),
        },
      )
      .then(() => {
        emitRealTimeUpdate('edited', { id: projectId });
      });
  }

  /**
    @param {number} projectId - The project id which we want to request access
    @param {string} feature - The name of the feature
  */
  function requestProjectAccess(projectId, feature) {
    const payload = { projectPermissionsForFeature: feature };
    return api.post(`/projects/api/v3/projects/${projectId}/requestaccess.json`, payload, {
      headers: {
        'Socket-ID': socketId,
      },
      errorMessage: t('Failed to request project access'),
    });
  }

  /**
    @param {number} projectId - The project id which we want to request update from
    @param {object} payload - the userId and content
  */
  function requestProjectUpdate(projectId, payload) {
    return api.put(`/projects/${projectId}/update/request.json`, payload, {
      headers: {
        'Socket-ID': socketId,
      },
      errorMessage: t('Failed to request project update'),
    });
  }

  /**
   * Create a project health update on a specific project
   *
   * - `0` - none
   * - `1` - needs attention
   * - `2` - on track
   * - `3` - at risk
   * @param {number} projectId
   * @param {{ update: { health: 0|1|2|3, text: string}, notifyIds: Array<number|string>, notifyAll: boolean }} projectUpdate
   */
  function createProjectUpdate(projectId, projectUpdate) {
    const promise = api
      .post(`/projects/${projectId}/update.json`, projectUpdate, {
        headers: { 'Socket-ID': socketId },
        errorMessage: t('Failed to create project update'),
      })
      .then(() => {
        emitRealTimeUpdate('edited', { id: projectId });
      });

    emitOptimisticUpdate(promise, 'update', {
      id: projectId,
      update: {
        ...projectUpdate.update,
        active: true, // sets the update as active
      },
    });

    return promise;
  }

  /**
   * add custom field to project
   * @param {number} projectId
   * @param {object} update
   */
  function createProjectCustomfield({ project, customfieldProject }) {
    const promise = api
      .post(
        `/projects/api/v3/projects/${project.id}/customfields.json`,
        { customfieldProject },
        {
          headers: { 'Socket-ID': socketId },
          errorMessage: t('Failed to add custom field to project'),
        },
      )
      .then(() => emitRealTimeUpdate('edited', { id: project.id }));

    emitOptimisticUpdate(promise, 'update', {
      ...project,
      customFieldValues: [...project.customFieldValues, customfieldProject],
    });

    return promise;
  }

  /**
   * update existing custom field on project
   * @param {number} projectId
   * @param {object} update
   */
  function updateProjectCustomfield({ id, projectId, customFieldId, value }) {
    const promise = api
      .put(
        `/projects/api/v3/projects/${projectId}/customfields/${id}.json`,
        {
          customfieldProject: {
            customfieldId: customFieldId,
            urlTextToDisplay: '',
            value,
          },
        },
        {
          headers: { 'Socket-ID': socketId },
          errorMessage: t('Failed to update custom field on project'),
        },
      )
      .then(() => emitRealTimeUpdate('edited', { id: projectId }));
    return promise;
  }

  /**
   * delete custom field from project
   * @param {number} projectId
   * @param {object} update
   */
  function deleteProjectCustomfield({ project, customfieldProject }) {
    const promise = api
      .delete(`/projects/api/v3/projects/${project.id}/customfields/${customfieldProject.id}.json`, {
        headers: { 'Socket-ID': socketId },
        errorMessage: t('Failed to delete project custom field'),
      })
      .then(() => emitRealTimeUpdate('edited', { id: project.id }));

    emitOptimisticUpdate(promise, 'update', {
      ...project,
      customFieldValues: project.customFieldValues.filter(({ id }) => id !== customfieldProject.id),
    });

    return promise;
  }

  /**
   * move project links to a different project
   * @param {number} projectId
   * @param {object} params
   */
  function moveProjectLinks(projectId, params) {
    const promise = api
      .put(
        `/projects/${projectId}/links/move.json`,
        { ...params },
        {
          headers: { 'Socket-ID': socketId },
          errorMessage: t('Failed to move project links'),
        },
      )
      .then(() => emitRealTimeUpdate('edited', { id: projectId }));
    return promise;
  }

  /**
   * copy project links to a different project
   * @param {number} projectId
   * @param {object} params
   */
  function copyProjectLinks(projectId, params) {
    const promise = api
      .put(
        `/projects/${projectId}/links/copy.json`,
        { ...params },
        {
          headers: { 'Socket-ID': socketId },
          errorMessage: t('Failed to copy project links'),
        },
      )
      .then(() => emitRealTimeUpdate('edited', { id: projectId }));
    return promise;
  }

  return {
    createProjectCustomfield,
    cloneProject,
    copyProjectLinks,
    convertTentativeProjectToNormal,
    createProject,
    createProjectFromJSON,
    createProjectUpdate,
    createTentativeProject,
    deleteProject,
    deleteProjectCustomfield,
    moveProjectLinks,
    requestProjectAccess,
    requestProjectUpdate,
    toggleStarProject,
    toggleArchiveProject,
    toggleCompleteProject,
    updateProject,
    updateProjectCustomfield,
    updateProjectEmailAddress,
    updateProjectFeatureOrder,
    updateProjectTags,
    updateProjectUsers,
  };
}
