<script setup>
const props = defineProps({
  users: {
    type: Array,
    default: () => [],
  },
  canAssignUsers: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['created']);

const PeopleSkillsCreateDialogPanel = defineAsyncComponent(() => import('./PeopleSkillsCreateDialogPanel.vue'));
</script>

<template>
  <LscDialog>
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }">
      <PeopleSkillsCreateDialogPanel v-bind="props" @close="close" @created="emit('created', $event)" />
    </template>
  </LscDialog>
</template>
