<script setup>
import {
  useCurrentAccount,
  useCurrentUser,
  useCurrentUserActions,
  useExperiment46,
  useFeatures,
  usePermissions,
  useProductUpdatesV1Loader,
} from '@/api';
import { useQuickViewRoute, useRoute } from '@/route';
import { useI18n, useKeyboardShortcut } from '@/util';
import { BadgeLevelProgressBar, BadgeLevelUser, useBadge } from '@/module/badge';
import { useLegacyBridge } from '@/module/legacy';
import { useDarkMode } from '../../darkMode/useDarkMode';
import { useAppShellSidebar } from '../useAppShellSidebar';
import { useAppShellSidebarTracking } from '../useAppShellSidebarTracking';
import AppShellSidebarUserMenuMoreItems from './AppShellSidebarUserMenuMoreItems.vue';
import AppShellSidebarUserMenuSwitchAccounts from './AppShellSidebarUserMenuSwitchAccounts.vue';
import AppShellSidebarUserMenuThemeOptions from './AppShellSidebarUserMenuThemeOptions.vue';

defineOptions({
  inheritAttrs: false,
});

const modelValue = defineModel({
  type: Boolean,
  default: false,
});

const { t } = useI18n();
const user = useCurrentUser();
const account = useCurrentAccount();
const route = useRoute();
const quickViewRoute = useQuickViewRoute();

const { showLegacyModal } = useLegacyBridge();
const { logout, toggleClockIn } = useCurrentUserActions();
const { canViewSettings, canViewGlobalSettingsCustomfields, isAccount, isOwnerAdmin } = usePermissions();
const { clockInOutEnabled, darkModeEnabled } = useFeatures();
const { isMyShortcutsOpen, isSidebarExpanded } = useAppShellSidebar();
const { trackProfileMenuItemClicked } = useAppShellSidebarTracking({ isSidebarExpanded });
const { colorMode: themeColorSelected } = useDarkMode();

const productUpdatesCount = shallowRef(-1);
const productUpdatesState = useProductUpdatesV1Loader({
  count: productUpdatesCount,
  pageSize: 1,
  params: { skipCounts: false },
});
watch(modelValue, () => {
  if (modelValue.value) {
    productUpdatesCount.value = 1;
  }
});

const productUpdatesUnreadCount = computed(() => productUpdatesState?.meta.value?.unreadCount || 0);

const { currentLevel, nextLevelProgress, nextLevel } = useBadge();
const { isExp46Variation } = useExperiment46();

// Edge case where a non admin can access settings to see the custom fields only
const defaultSettingsRoute = computed(() => {
  return !isOwnerAdmin.value && canViewGlobalSettingsCustomfields.value ? '/settings/customfields' : '/settings';
});

const shouldShowPlayground = computed(() => import.meta.env.DEV || account.value.isStaging);
const shouldShowOpenInStaging = computed(() => import.meta.env.DEV && import.meta.env.TW_APP_PROXY_TARGET);
const currentRouteInStaging = computed(() => {
  const path = quickViewRoute.fullPath ?? route.fullPath;
  return new URL(path, import.meta.env.TW_APP_PROXY_TARGET).href;
});

function openMyShortcuts() {
  isMyShortcutsOpen.value = !isMyShortcutsOpen.value;
}

function editMyDetailsClicked() {
  showLegacyModal({ modalName: 'addOrEditPerson', params: { personId: user.value.id } });
  trackProfileMenuItemClicked('edit_my_details');
}

function toggleClockClicked() {
  toggleClockIn();
  trackProfileMenuItemClicked('toggle_clock');
}

function logoutClicked() {
  logout();
  trackProfileMenuItemClicked('logout');
}

useKeyboardShortcut('Shift S', openMyShortcuts);
</script>

<template>
  <LscMenu v-bind="$attrs" v-model="modelValue" offset="16">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <VList v-bind="VListRounded" class="max-w-72">
      <VListItem
        v-LsdTooltip="`${user.firstName} ${user.lastName}`"
        twoLine
        :to="`/people/${user.id}`"
        @click="trackProfileMenuItemClicked('view_profile')"
      >
        <template #prepend>
          <LscAvatar v-bind="getLsAvatarProps({ user })" size="lg" class="mr-2" />
        </template>
        <VListItemTitle class="!text-body-1 font-semibold !text-default">
          <LscOverflowEllipsis>{{ user.firstName }} {{ user.lastName }}</LscOverflowEllipsis>
        </VListItemTitle>
        <template #append>
          <div v-if="isExp46Variation && currentLevel">
            <BadgeLevelUser :code="currentLevel.code" />
          </div>
        </template>
        <BadgeLevelProgressBar v-if="isExp46Variation && nextLevel !== null" :progress="nextLevelProgress" />
        <VListItemSubtitle v-else class="!text-body-2 !text-subtle">{{ t('View profile') }}</VListItemSubtitle>
      </VListItem>

      <div class="mx-3 border-t border-separator" />

      <VListItem class="focus-visible:bg-selected" tabindex="0" @click="editMyDetailsClicked">
        <template #prepend>
          <LscIcon icon="lsi-edit" />
        </template>
        <VListItemTitle>{{ t('Edit my details') }}</VListItemTitle>
      </VListItem>
      <VListItem v-if="isAccount && clockInOutEnabled" @click="toggleClockClicked">
        <template #prepend>
          <LscIcon icon="lsi-time" />
        </template>
        <VListItemTitle>{{ user.isClockedIn ? t('Clock out') : t('Clock in') }}</VListItemTitle>
      </VListItem>

      <div class="mx-3 border-t border-separator" />

      <VListItem v-if="canViewSettings" :to="defaultSettingsRoute" @click="trackProfileMenuItemClicked('settings')">
        <template #prepend>
          <LscIcon icon="lsi-settings" />
        </template>
        <VListItemTitle>{{ t('Settings') }}</VListItemTitle>
      </VListItem>

      <AppShellSidebarUserMenuThemeOptions v-if="darkModeEnabled">
        <template #activator="activator">
          <VListItem
            data-identifier="sidebar-user-menu-theme-options"
            class="focus-visible:bg-selected"
            v-bind="activator.props"
            :active="activator.isActive"
          >
            <template #prepend>
              <LscIcon v-if="themeColorSelected === 'system'" icon="lsi-theme-light" />
              <LscIcon v-else :icon="themeColorSelected === 'light' ? 'lsi-theme-light' : 'lsi-theme-dark'" />
            </template>
            <div class="flex items-center gap-2">
              <VListItemTitle>{{ t('Theme') }}</VListItemTitle>
              <LscLabel variant="emphasis">Beta</LscLabel>
            </div>
            <template #append>
              <LscIcon icon="lsi-open-panel" size="sm" />
            </template>
          </VListItem>
        </template>
      </AppShellSidebarUserMenuThemeOptions>

      <VListItem
        v-if="isOwnerAdmin"
        :to="`/settings/subscription`"
        @click="trackProfileMenuItemClicked('subscription')"
      >
        <template #prepend>
          <LscIcon icon="lsi-subscription" />
        </template>
        <VListItemTitle>{{ t('Subscription') }}</VListItemTitle>
      </VListItem>

      <VListItem v-if="isAccount" :to="`/people/${user.id}/tasks`" @click="trackProfileMenuItemClicked('my_tasks')">
        <template #prepend>
          <LscIcon icon="lsi-task" />
        </template>
        <VListItemTitle>{{ t('My tasks') }}</VListItemTitle>
      </VListItem>

      <VListItem
        v-if="isExp46Variation"
        :to="`/people/${user.id}/badges`"
        @click="trackProfileMenuItemClicked('my_badges')"
      >
        <template #prepend>
          <LscIcon icon="lsi-badge" />
        </template>
        <VListItemTitle>{{ t('My badges') }}</VListItemTitle>
      </VListItem>

      <VListItem
        v-if="isAccount"
        :to="`/people/${user.id}/integrations`"
        @click="trackProfileMenuItemClicked('my_apps')"
      >
        <template #prepend>
          <LscIcon icon="lsi-my-apps" />
        </template>
        <VListItemTitle>{{ t('My apps') }}</VListItemTitle>
      </VListItem>

      <VListItem
        v-if="isOwnerAdmin"
        :to="`/settings/integrations`"
        @click="trackProfileMenuItemClicked('apps_and_integrations')"
      >
        <template #prepend>
          <LscIcon icon="lsi-sidenav-other-apps" />
        </template>
        <VListItemTitle>{{ t('Apps and integrations') }}</VListItemTitle>
      </VListItem>

      <AppShellSidebarUserMenuMoreItems :productUpdatesUnreadCount="productUpdatesUnreadCount">
        <template #activator="activator">
          <VListItem class="focus-visible:bg-selected" v-bind="activator.props" :active="activator.isActive">
            <template #prepend><LscIcon icon="lsi-options" /></template>
            <VListItemTitle>{{ t('More') }}</VListItemTitle>
            <template #append>
              <LscBadge v-if="productUpdatesUnreadCount > 0" :count="productUpdatesUnreadCount" variant="highlight" />
              <LscIcon icon="lsi-open-panel" size="sm" />
            </template>
          </VListItem>
        </template>
      </AppShellSidebarUserMenuMoreItems>

      <div class="mx-3 border-t border-separator" />

      <VListItem v-if="shouldShowOpenInStaging" :href="currentRouteInStaging" target="_blank" link>
        <template #prepend>
          <LscIcon icon="lsi-website" class="!text-icon-primary-default" />
        </template>
        <VListItemTitle>
          <span class="text-primary">Open on {{ account.name }}</span>
        </VListItemTitle>
      </VListItem>

      <VListItem v-if="shouldShowPlayground" :to="`/playground`" link>
        <template #prepend>
          <LscIcon icon="lsi-dev" class="!text-icon-primary-default" />
        </template>
        <VListItemTitle>
          <span class="text-primary">Dev playground</span>
        </VListItemTitle>
      </VListItem>

      <AppShellSidebarUserMenuSwitchAccounts v-if="user.accounts.length > 0 || account.isPartner" />

      <VListItem class="focus-visible:bg-selected" link tabindex="0" @click="logoutClicked">
        <template #prepend>
          <LscIcon icon="lsi-logout" />
        </template>
        <VListItemTitle>{{ t('Logout') }}</VListItemTitle>
      </VListItem>
    </VList>
  </LscMenu>
</template>
