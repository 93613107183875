import { useI18n } from '@/util';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { itemTypeToApiType, itemTypeToRestoreEventAction } from './constants';

export function useTrashcanActions() {
  const api = useAxios();
  const { t } = useI18n();
  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();
  const { socketId, emit: emitRealTimeUpdate } = useRealTimeUpdates();

  return {
    /**
     * @param {object} item
     * @param {string} item.type
     * @param {number} item.id
     */
    restoreItem(item) {
      const promise = api
        .put(
          `/trashcan/${itemTypeToApiType[item.type]}/${item.id}/restore.json`,
          { includeComments: true },
          {
            headers: { 'Socket-ID': socketId },
            errorMessage: t('Failed to restore item'),
          },
        )
        .then(() => {
          emitRealTimeUpdate({
            action: itemTypeToRestoreEventAction[item.type],
            type: item.type,
            id: item.id,
            projectId: item.projectId,
            tasklistId: item.tasklistId,
            parentTaskId: item.parentTaskId,
            fileId: item.fileId,
            fileVersionId: item.fileVersionId,
          });
        });

      emitOptimisticUpdate({
        promise,
        type: 'trashCanItem',
        action: 'update',
        trashCanItem: {
          ...item,
          wasRestored: true,
        },
      });

      return promise;
    },
  };
}
