<script setup>
import { useCurrentAccount, usePermissions } from '@/api';
import { AppShellDrawerPanel, AppShellDrawerTitle, useAppShellSidebar } from '@/appShell';
import { useI18n } from '@/util';
import { useLegacyBridge } from '@/module/legacy';
import TemplatesDrawerProjects from './TemplatesDrawerProjects.vue';
import TemplatesDrawerTaskLists from './TemplatesDrawerTaskLists.vue';
import { useTemplatesDrawer } from './useTemplatesDrawer';

const { selectedTab } = useTemplatesDrawer();
const { activeDrawerPanel, clearActiveDrawerPanelIfNotPinned } = useAppShellSidebar();
const { showLegacyModal } = useLegacyBridge();
const { canAddProjects, canViewSettings, canViewProjectTemplates } = usePermissions();
const { t } = useI18n();
const account = useCurrentAccount();

const searchTerm = shallowRef('');

const templatesProjectId = computed(() => account.value?.tasklistTemplateProjectId);

function openTemplateProjectModal() {
  showLegacyModal({
    modalName: 'addOrEditProjectWizard',
    params: {
      type: 'projects-template',
    },
  });
}
</script>

<template>
  <AppShellDrawerPanel v-if="activeDrawerPanel === 'templates'" data-identifier="side-nav-jump-to-panel">
    <template #header>
      <AppShellDrawerTitle
        :title="t('Templates')"
        pinDataIdentifier="side-nav-templates-panel-pin-icon"
        closeDataIdentifier="side-nav-templates-panel-close-icon"
      >
        <template #prepend-icons="{ buttonSize }">
          <LscIconButton
            v-if="selectedTab === 'projects' && canAddProjects"
            v-LsdTooltip="t('Add project template')"
            :ariaLabel="t('Add project template')"
            :size="buttonSize"
            icon="lsi-add"
            data-identifier="side-nav-templates-panel-add-project-template-icon"
            @click="openTemplateProjectModal"
          />
          <LscIconButton
            v-if="selectedTab === 'tasklists' && canViewSettings && templatesProjectId"
            v-LsdTooltip="t('Add task list template')"
            :size="buttonSize"
            :ariaLabel="t('Add task list template')"
            icon="lsi-add"
            data-identifier="side-nav-templates-panel-add-task-list-template-icon"
            @click="
              showLegacyModal({
                modalName: 'addOrEditTaskListTemplate',
                params: {
                  projectId: templatesProjectId,
                },
              })
            "
          />
        </template>
      </AppShellDrawerTitle>
    </template>
    <template #content>
      <div v-if="canViewProjectTemplates" class="m-0 flex h-full flex-col overflow-hidden p-0">
        <VTextField
          :key="activeDrawerPanel"
          v-model.trim="searchTerm"
          v-bind="VTextFieldPanelSearch"
          autofocus
          class="mx-6 grow-0"
          :placeholder="t('Search')"
        />

        <div v-if="!searchTerm" class="!mt-2 grow-0 px-6">
          <VTabs v-bind="VTabsUnderline" v-model="selectedTab">
            <VTab value="projects" data-identifier="projects-panel-tab-projects">{{ t('Projects') }}</VTab>
            <VTab value="tasklists" data-identifier="projects-panel-tab-tasklists">{{ t('Tasks') }}</VTab>
          </VTabs>
        </div>

        <div class="h-full overflow-y-auto">
          <KeepAlive>
            <TemplatesDrawerProjects v-if="searchTerm || selectedTab !== 'tasklists'" :searchTerm="searchTerm" />
            <TemplatesDrawerTaskLists v-else-if="selectedTab === 'tasklists'" />
          </KeepAlive>
        </div>
      </div>
      <LscEmptyState
        v-else
        size="md"
        variant="error"
        class="mx-6 flex h-full items-center justify-center"
        :title="t('No access')"
        :message="t('You do not have access to project templates. Please contact your administrator.')"
      ></LscEmptyState>
    </template>
    <template #footer>
      <div class="my-7 flex flex-col gap-4">
        <div class="h-px w-full border-t border-separator-on-surface-default" />
        <RouterLink
          v-if="selectedTab === 'projects'"
          to="/projects/templates/"
          class="flex w-full items-center gap-2 text-body-1 font-semibold text-primary no-underline"
          data-identifier="side-nav-templates-panel-project-templates-home-button"
          @click="clearActiveDrawerPanelIfNotPinned"
        >
          <LscIcon icon="lsi-single-template" />
          {{ t('Project templates home') }}
          <LscIcon class="ml-auto" icon="lsi-open-panel" />
        </RouterLink>
        <RouterLink
          v-if="selectedTab === 'tasklists'"
          to="/task-templates"
          class="flex w-full items-center gap-2 text-body-1 font-semibold text-primary no-underline"
          data-identifier="side-nav-templates-panel-task-list-templates-home-button"
          @click="clearActiveDrawerPanelIfNotPinned"
        >
          <LscIcon icon="lsi-single-template" />
          {{ t('Task list templates home') }}
          <LscIcon class="ml-auto" icon="lsi-open-panel" />
        </RouterLink>
      </div>
    </template>
  </AppShellDrawerPanel>
</template>
