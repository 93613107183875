<script setup>
import { useWindowSize } from '@/util';
import { useLsDraggable } from '../../../composables/draggable/useLsDraggable.js';
import { LscDialogCardSizes } from './LscDialog.types';

// NOTE: THIS COMPONENT IS DEPRECATED AND WILL BE REMOVED IN THE FUTURE
// PLEASE USE `LscDialogCard` INSTEAD

defineProps({
  title: {
    type: String,
    default: '',
  },
  closeButtonText: {
    type: String,
    default: '',
  },
  saveButtonText: {
    type: String,
    default: '',
  },
  saveButtonPrependIcon: {
    type: String,
    default: '',
  },
  saveButtonDisabled: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  hasFooter: {
    type: Boolean,
    default: true,
  },
  /* Set a minimum height to prevent janking of UI */
  minHeight: {
    type: Number,
    default: 40,
  },
  critical: {
    type: Boolean,
    default: false,
  },
  dataIdentifierPrefix: {
    type: String,
    default: undefined,
  },
  /**
   * The size of the dialog.
   * @type {PropType<typeof LscDialogCardSizes[number]>}
   */
  size: {
    type: String,
    default: 'sm',
    validator: (size) => LscDialogCardSizes.includes(size),
  },
});

const emit = defineEmits(['close', 'save']);

const { draggable, resetPosition } = useLsDraggable();
const { width } = useWindowSize();
const card = useTemplateRef('WidgetDialogPanel');
const dragHandle = shallowRef(null);

watch(width, () => {
  if (card.value) {
    resetPosition(card.value);
  }
});

onMounted(() => {
  if (card.value && dragHandle.value) {
    draggable(card.value, { dragHandle: dragHandle.value });
  }
});

// the canceled parameter was added to track whether the dialog was closed by clicking the close button
// it can be removed once the experiment is done
function close(canceled) {
  emit('close', canceled);
}

function save() {
  emit('save');
}
</script>

<template>
  <div
    ref="WidgetDialogPanel"
    target="VDialogCard"
    class="pointer-events-auto w-full rounded-md bg-[--lsds-c-dialog-color-background-default] shadow-dialog"
    :class="{
      'max-w-[--lsds-c-dialog-size-xs]': size === 'xs',
      'max-w-[--lsds-c-dialog-size-sm]': size === 'sm',
      'max-w-[--lsds-c-dialog-size-md]': size === 'md',
      'max-w-[--lsds-c-dialog-size-lg]': size === 'lg',
      'max-w-[--lsds-c-dialog-size-full]': size === 'full',
    }"
  >
    <slot name="header">
      <div ref="dragHandle" class="flex items-center p-6 text-h4 font-semibold">
        <slot name="before-header-title" />
        <slot name="title">
          <span :data-identifier="dataIdentifierPrefix ? `${dataIdentifierPrefix}-title` : undefined">{{ title }}</span>
        </slot>
        <slot name="after-header-title" />
        <div class="flex-1" />
        <slot name="before-header-close-btn" />

        <slot name="header-close-btn">
          <LscIconButton
            v-LsdTooltip="closeButtonText"
            size="md"
            variant="primary"
            icon="lsi-close"
            :ariaLabel="closeButtonText"
            @click="close(false)"
          />
        </slot>
      </div>
    </slot>

    <form
      id="dialogForm"
      class="min-h-[--min-height] px-6 pt-2"
      :style="{ '--min-height': minHeight ? `${minHeight}px` : undefined }"
      @submit.prevent="save"
    >
      <slot />
    </form>

    <template v-if="hasFooter">
      <div v-if="closeButtonText || saveButtonText" class="m-6 flex min-h-10 p-0">
        <LscButton
          v-if="closeButtonText"
          variant="tertiary"
          :data-identifier="dataIdentifierPrefix ? `${dataIdentifierPrefix}-cancel-button` : undefined"
          @click="close(true)"
        >
          {{ closeButtonText }}
        </LscButton>
        <slot name="after-cancel-btn" />
        <div class="flex-1" />
        <slot name="before-save-btn" />
        <LscButton
          v-if="saveButtonText"
          type="submit"
          :variant="critical ? 'critical' : 'primary'"
          :loading="isLoading"
          :prependIcon="saveButtonPrependIcon"
          form="dialogForm"
          :disabled="saveButtonDisabled || isLoading"
          :data-identifier="dataIdentifierPrefix ? `${dataIdentifierPrefix}-submit-button` : undefined"
          @click.prevent.stop="save"
        >
          {{ saveButtonText }}
        </LscButton>
      </div>

      <template v-if="$slots['after-footer']">
        <div class="border-t border-separator" />

        <slot name="after-footer" />
      </template>
    </template>
  </div>
</template>
