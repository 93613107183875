<script setup>
import { useHotjar } from '@/api';
import { useI18n } from '@/util';
import OnboardingWizardCommonChoiceExtended from '../common/OnboardingWizardCommonChoiceExtended.vue';
import OnboardingWizardCommonStep from '../common/OnboardingWizardCommonStep.vue';
import { STEP_SELECT_GOAL } from '../constants';

const props = defineProps({
  nextButtonText: {
    type: String,
    required: true,
  },
  state: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['nextStep']);

const { t } = useI18n();
const { trackEvent } = useHotjar();

const { [STEP_SELECT_GOAL]: stateData } = props.state;
const goals = [
  {
    id: 1,
    icon: 'lsi-group',
    label: t('Planning and managing resources'),
    description: t(
      "See staff workloads in real time, adapt to changes, see who's free before taking on future projects and check if you're under or over utilizing your team",
    ),
  },
  {
    id: 2,
    icon: 'lsi-billable',
    label: t('Maximizing billable time and project profitability'),
    description: t('Keep tabs on every billable hour, stick to budgets and know when things become unprofitable'),
  },
  {
    id: 3,
    icon: 'lsi-insights',
    label: t('Reporting with confidence, and forecasting for success'),
    description: t("Know what's what with real-time insights into project health, profitability, and team utilization"),
  },
  {
    id: 4,
    icon: 'lsi-project',
    label: t('Managing projects more easily - from start to finish'),
    description: t(
      'Streamline project workflows, centralize requests in one place, simplify communication and approvals',
    ),
  },
];

const selectedGoalId = shallowRef(stateData?.selectedGoalId);

function nextStep() {
  emit('nextStep', !stateData || stateData?.selectedGoalId !== selectedGoalId.value, {
    selectedGoalId: selectedGoalId.value ?? -1,
  });
}

function skipStep() {
  emit('nextStep', !stateData || !stateData?.selectedGoalId !== -1, {
    selectedGoalId: -1,
    skipped: true,
  });
}
onMounted(() => {
  trackEvent('ONBOARDING_20_PLUS');
});
</script>

<template>
  <OnboardingWizardCommonStep class="flex items-center">
    <template #title>
      <span class="text-h2 font-semibold">
        {{ t("What's the top challenge you want us to help you solve first?") }}
      </span>
    </template>
    <template #description>
      <div class="-mt-2 text-body-1">
        {{ t("We'll show you how to tackle your biggest challenge first, and then help you address the others") }}
      </div>
    </template>
    <div class="max-w-[--onboarding-wizard-select-goal-step-container-max-width]">
      <LscChoiceList v-model="selectedGoalId" :multiple="false" :mandatory="true" :limit="1">
        <OnboardingWizardCommonChoiceExtended
          v-for="{ id, icon, label, description } in goals"
          :key="id"
          :value="id"
          :label="label"
          :description="description"
        >
          <template #image>
            <LscIconButton :icon="icon" size="lg" variant="primary" />
          </template>
        </OnboardingWizardCommonChoiceExtended>
      </LscChoiceList>
      <slot
        name="underFieldsButtons"
        :isNextStepButtonDisabled="selectedGoalId === undefined"
        :nextButtonText="t('Next')"
        :nextStep="nextStep"
        :showSkipButton="true"
        :skipButtonText="t('I\'m not sure yet')"
        :skipStep="skipStep"
        :reverse="true"
      />
    </div>
  </OnboardingWizardCommonStep>
</template>
