<script setup>
import { useI18n } from '@/util';
import { useAppShellSidebar } from './useAppShellSidebar';
import { useAppShellSidebarTracking } from './useAppShellSidebarTracking';

const { t } = useI18n();

const {
  isItemActive,
  setItemPinned,
  moreNavItems,
  isSidebarExpanded,
  toggleActiveDrawerPanel,
  clearActiveDrawerPanelIfNotPinned,
} = useAppShellSidebar();
const { trackLeftNavigationClicked, trackLeftNavigationItemPinning } = useAppShellSidebarTracking({
  isSidebarExpanded,
});

const isMoreMenuOpen = shallowRef(false);

const isMoreMenuActive = computed(() => {
  return moreNavItems.value.some(isItemActive);
});

function pinItem(item) {
  setItemPinned(item.itemKey, true);
  isMoreMenuOpen.value = false;
  trackLeftNavigationItemPinning('pinned', item.pendoLabel);
}

function handleItemClicked(item) {
  trackLeftNavigationClicked(item.pendoLabel);
  if (item.drawerPanel) {
    toggleActiveDrawerPanel(item.drawerPanel);
  } else {
    clearActiveDrawerPanelIfNotPinned();
  }
}
</script>

<template>
  <WidgetOptionsMenu v-model="isMoreMenuOpen" location="end top">
    <template #activator="activator">
      <slot
        name="activator"
        v-bind="{
          ...activator,
          isActive: activator.isActive || isMoreMenuActive,
        }"
      />
    </template>
    <WidgetOptionsMenuItem
      v-for="item in moreNavItems"
      :key="item.itemKey"
      :to="item.path"
      :exact="item.exact"
      tabindex="0"
      class="group/item focus-visible:group-focus-within:bg-selected"
      link
      density="compact"
      :active="isItemActive(item)"
      :data-identifier="item.dataIdentifier"
      @click="handleItemClicked(item)"
    >
      <template #prepend>
        <LscIcon :icon="item.icon" />
      </template>
      <VListItemTitle class="inline-flex items-center gap-2">
        {{ item.text }}
      </VListItemTitle>
      <template #append>
        <LscIconButton
          v-if="!item.fixed"
          v-LsdTooltip="t('Pin')"
          :ariaLabel="t('Pin')"
          class="invisible group-focus-within/item:visible group-hover/item:visible"
          size="sm"
          icon="lsi-pin"
          @click.prevent="pinItem(item)"
        />
      </template>
    </WidgetOptionsMenuItem>
  </WidgetOptionsMenu>
</template>
