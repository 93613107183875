import { useCurrenciesV3Loader, useCurrentAccount } from '@/api';
import { useI18n } from '@/util';
import { customfieldFormats } from '../../constants';

export function useFormulaAdvancedOptions() {
  const account = useCurrentAccount();
  const { t } = useI18n();

  const selectedCurrencyCode = shallowRef(account.value.currency.code);

  const { items: currencies } = useCurrenciesV3Loader({ count: Infinity });

  const symbol = computed({
    get: () =>
      currencies.value?.find((currency) => currency.code === selectedCurrencyCode.value) ?? account.value.currency,
    set: (currency) => {
      selectedCurrencyCode.value = currency?.code ?? null;
    },
  });

  function getCurrencyTitle({ code, name, symbol: currencySymbol }) {
    return currencySymbol ? `${code} - ${name} (${currencySymbol})` : `${code} - ${name}`;
  }

  const formats = computed(() => {
    const types = [
      { value: customfieldFormats.NUMBER, title: t('Scalar (e.g. 25)') },
      { value: customfieldFormats.CURRENCY, title: t('Currency (e.g. $500)') },
      { value: customfieldFormats.TIME, title: t('Time (e.g. 5h)') },
      { value: customfieldFormats.CURRENCY_TIME, title: t('Currency / Time (e.g. 80$/h)') },
      { value: customfieldFormats.TIME_CURRENCY, title: t('Time / Currency (e.g. 0.22 h/$)') },
      { value: customfieldFormats.DATE, title: t('Date (e.g. 26/06/2025)') },
      { value: customfieldFormats.TEXT, title: t('String (e.g. "Today")') },
      { value: customfieldFormats.PERCENTAGE, title: t('Percentage (e.g. 42.8%)') },
      { value: customfieldFormats.CUSTOM, title: t('Custom') },
    ];

    return types.map((fieldType) => ({ ...fieldType }));
  });

  const decimalOptions = computed(() => [
    { value: 0, title: '0' },
    { value: 1, title: '1' },
    { value: 2, title: '2' },
    { value: 3, title: '3' },
    { value: 4, title: '4' },
  ]);

  const showSymbol = computed(() => (format) => {
    if (!format) return false;
    return [customfieldFormats.CURRENCY, customfieldFormats.CURRENCY_TIME, customfieldFormats.TIME_CURRENCY].includes(
      format,
    );
  });

  const showDecimals = computed(() => (format) => {
    if (!format) return false;
    return [
      customfieldFormats.NUMBER,
      customfieldFormats.CURRENCY,
      customfieldFormats.CURRENCY_TIME,
      customfieldFormats.TIME_CURRENCY,
      customfieldFormats.PERCENTAGE,
    ].includes(format);
  });

  return {
    currencies,
    symbol,
    getCurrencyTitle,
    formats,
    showSymbol,
    showDecimals,
    decimalOptions,
  };
}
