<script setup lang="ts">
import { useElementHover, useEventListener } from '@vueuse/core';
import { type LscDropzoneSize, LscDropzoneSizes } from './LscDropzone.types.js';
import LscDropzoneOverlay from './LscDropzoneOverlay.vue';
import LscDropzonePlaceholder from './LscDropzonePlaceholder.vue';
import { provideLscDropzone } from './useLscDropzone.js';

const props = defineProps({
  /** Controls the disabled state of the dropzone. */
  disabled: {
    type: Boolean,
    default: false,
  },
  /** Custom error state for the dropzone. This will override the local error state. */
  error: {
    type: Boolean,
    default: false,
  },
  /** Custom error message for the dropzone. This will override the error message inside the dropzone. */
  errorMessage: {
    type: String,
    default: undefined,
  },
  /** The file extensions that the dropzone will accept.*/
  extensions: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  /** The maximum size of the file that the dropzone will accept. */
  maxFileSize: {
    type: Number,
    default: Infinity,
  },
  /** The custom message to be displayed in the dropzone. Otherwise it will display the formats accepted (if any). */
  message: {
    type: String,
    default: undefined,
  },
  /** Allow the dropzone to accept multiple files. */
  multiple: {
    type: Boolean,
    default: false,
  },
  /** The size of the dropzone. */
  size: {
    type: String as PropType<LscDropzoneSize>,
    default: 'md',
    validator: (value: LscDropzoneSize) => LscDropzoneSizes.includes(value),
  },
  /** Enables the visibility of a hint text listing the supported file formats. */
  showMessage: {
    type: Boolean,
    default: true,
  },
});

const modelValue = defineModel<File[]>({
  type: Array,
  default: () => [],
});

const dropzoneRef = useTemplateRef('dropzoneRef') as Ref<HTMLElement>;

const isElementHovered = useElementHover(dropzoneRef);

const { isOverDropZone, selectFiles } = provideLscDropzone({
  disabled: computed(() => props.disabled),
  dropzoneRef,
  error: computed(() => props.error),
  errorMessage: computed(() => props.errorMessage),
  extensions: computed(() => props.extensions),
  maxFileSize: computed(() => props.maxFileSize),
  message: computed(() => props.message),
  modelValue,
  multiple: computed(() => props.multiple),
  showMessage: computed(() => props.showMessage),
  size: computed(() => props.size),
});

useEventListener(document, 'paste', (event) => {
  if (!isElementHovered.value || props.disabled) {
    return;
  }
  const files = Array.from(event.clipboardData?.files ?? []);
  if (files.length) {
    selectFiles(files);
  }
});
</script>
<template>
  <div ref="dropzoneRef" class="relative h-full">
    <slot
      :isOverDropZone="isOverDropZone"
      :selectFiles="selectFiles"
      :modelValue="modelValue"
      :error="error"
      :errorMessage="errorMessage"
    >
      <LscDropzonePlaceholder v-if="!disabled" class="h-full" />
    </slot>
    <Transition
      appearFromClass="opacity-0"
      leaveToClass="opacity-0"
      appearActiveClass="transition-opacity"
      leaveActiveClass="transition-opacity"
    >
      <LscDropzoneOverlay v-if="isOverDropZone" />
    </Transition>
  </div>
</template>
