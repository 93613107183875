<script setup>
import { useProjectsV3Loader } from '@/api';
import { useI18n } from '@/util';
import ProjectJumpToDrawerProjectsList from './ProjectJumpToDrawerProjectsList.vue';

const props = defineProps({
  projectCategoryId: {
    type: [Number, null],
    required: true,
  },
});

const { t } = useI18n();

const numOfItems = 50;
const count = shallowRef(numOfItems);
const state = useProjectsV3Loader({
  count,
  pageSize: numOfItems,
  params: {
    'searchTerm': null,
    'include': 'companies',
    'includeProjectUserInfo': true,
    'fields[projects]': 'id,name,isStarred,companyId,startPage',
    'projectCategoryIds': props.projectCategoryId,
    'orderBy': 'name',
    'orderMode': 'asc',
  },
});
</script>

<template>
  <!-- Note that we use useNewList="false" and return list items here because this is embedded in an existing list -->
  <ProjectJumpToDrawerProjectsList
    v-model:count="count"
    :useNewList="false"
    :step="numOfItems"
    :state="state"
    tab="categories"
  >
    <template #blank>
      <VListItem v-bind="VListItemSidebarProjectsDrawer" tabindex="-1">
        <template #prepend>
          <LscIcon :size="14" tabindex="-1" class="ml-[28px]" icon="lsi-tooltip" />
        </template>
        <VListItemTitle class="text-body-1 text-subtle">{{ t('No projects here yet') }}</VListItemTitle>
      </VListItem>
    </template>
    <template #loading>
      <VListItem v-bind="VListItemSidebarProjectsDrawer" tabindex="-1">
        <template #prepend>
          <LscIcon :size="15" tabindex="-1" class="ml-[28px] animate-spin" icon="lsi-loading" />
        </template>
        <VListItemTitle class="text-body-1 text-subtle">
          {{ t('Loading') }}
        </VListItemTitle>
      </VListItem>
    </template>
  </ProjectJumpToDrawerProjectsList>
</template>
