import { DateTime } from 'luxon';
import { useRouter } from 'vue-router';
import {
  useCompaniesActions,
  useCurrentAccount,
  useCurrentAccountActions,
  useCurrentUser,
  useExperimentA2420,
  useExperimentA27,
  useFeatures,
  useFeatureTrialActions,
  usePersonActions,
  usePreferences,
  useProjectActions,
  useProjectBudgetActions,
  useProjectFeatureOrderLoader,
  useTemplatesActions,
  useUserProductOutcomesActions,
  useUserRateActions,
} from '@/api';
import { useSampleProjects } from '@/appShell';
import { until, useI18n, useLocalStorage } from '@/util';
import { useActiveProductTourId } from '../../tour/useActiveProductTourId';
import {
  BILLING_TYPE_FIXED_FEE,
  BILLING_TYPE_NO_BUDGET,
  BILLING_TYPE_RETAINER,
  BUDGET_TYPE_TIME,
  PREVIEW_TAB_BOARD,
  PROJECT_TYPE_TEMPLATE,
  STEP_ADD_CLIENT,
  STEP_ADD_TASKS,
  STEP_BILLING_TYPE,
  STEP_BOARD_COLUMNS,
  STEP_CLIENT_PROJECT,
  STEP_GETTING_STARTED,
  STEP_GETTING_STARTED_CHECKLIST,
  STEP_INVITE_PEOPLE,
  STEP_INVITE_USERS_AND_SET_TEAM_RATES,
  STEP_PROJECT_NAME,
  STEP_PROJECT_TYPE_SELECTION,
  STEP_SELECT_VIEW,
  STEP_SET_BUDGET,
  STEP_SET_TEAM_RATES,
} from '../constants';
import { useOnboardingWizardFeatures } from '../useOnboardingWizardFeatures';

export function useOnboardingWizardLastStep() {
  const { t } = useI18n();
  const router = useRouter();
  const account = useCurrentAccount();
  const currentUser = useCurrentUser();
  const { updateAccount } = useCurrentAccountActions();
  const { createCompany: createClient } = useCompaniesActions();
  const {
    createProjectFromJSON,
    updateProjectFeatureOrder,
    cloneProject,
    createProject: createProjectBasic,
  } = useProjectActions();
  const { onboardingTemplateCreatedIds, onboardingTeamAddedOrRatedProjectIds, firstTimeExperienceVisibility } =
    usePreferences();
  const { createProjectBudget } = useProjectBudgetActions();
  const { sendInvite } = usePersonActions();
  const { updateUserCost, updateUserRate } = useUserRateActions();
  const {
    createSampleProjects,
    createSampleClientsAndUsers,
    createSampleTentativeProjects,
    createAndAssignJobRoles,
    assignUsersToTasks,
  } = useSampleProjects(true);
  const { createProjectFromSampleTemplate } = useTemplatesActions();
  const { saveSelectedProductOutcomes } = useUserProductOutcomesActions();
  const { onboardingFeatures } = useOnboardingWizardFeatures();
  const { isExpA27V3Variation, isExpA27Variation, trackExperimentA27, trackExperimentA27Variation } =
    useExperimentA27();
  const activeProductTourId = useActiveProductTourId();
  const { isExpA2420Variation } = useExperimentA2420();
  const { startFeatureTrial } = useFeatureTrialActions();
  const { newSchedulerInsights } = useFeatures();

  const projectIdToPrioritizeFinanceTab = shallowRef();
  const { item: featureOrder, loaded: featureOrderLoaded } = useProjectFeatureOrderLoader({
    projectId: projectIdToPrioritizeFinanceTab,
  });
  const showInsights = useLocalStorage('teamwork/planning/scheduler/showSchedulerInsights', true);

  const TAGS_COLLECTION = [
    {
      name: t('Review'),
      color: '#E8384F',
    },
    {
      name: t('Draft'),
      color: '#EEC202',
    },
    {
      name: t('Published'),
      color: '#63D16F',
    },
  ];

  async function saveProductOutcomes(state) {
    const { selectedItems = [] } = state[STEP_GETTING_STARTED] ?? state[STEP_GETTING_STARTED_CHECKLIST] ?? {};
    await saveSelectedProductOutcomes(currentUser.value.id, selectedItems);
  }

  async function saveProductOutcomesBasedOnSelectedGoal(selectedGoalId) {
    // Default outcomes for ICP customers
    const outcomeIdsToSave = [
      1, // General Project Management
      2, // Delivering Client Projects
    ];

    // Add extra outcomes based on selected goal
    switch (selectedGoalId) {
      case 1: // Goal: I want to boost team productivity and resource capacity
        outcomeIdsToSave.push(
          4, // Resource Management & Capacity Planning
        );
        break;
      case 2: // Goal: I want to maximize billable work and revenue potential
        outcomeIdsToSave.push(
          3, // Tracking billable time, budgets & invoices
          7, // Client Financial Management
        );
        break;
      case 3: // Goal: I want to gain insights into progress and performance
        outcomeIdsToSave.push(
          6, // Business & Project Reporting
        );
        break;
      case 4: // Goal: Managing projects more easily - from start to finish
        outcomeIdsToSave.pop();
        break;
      default:
        break;
    }

    await saveSelectedProductOutcomes(currentUser.value.id, outcomeIdsToSave);
  }

  function parseEmail(emailString) {
    const regex = /^([A-Za-z]+)\.([A-Za-z]+)@[^\s@]+$/;
    const match = regex.exec(emailString);
    if (match) {
      return {
        firstName: match[1],
        lastName: match[2],
      };
    }
    return {
      firstName: emailString.split('@')[0],
      lastName: '',
    };
  }

  function nextWeekDueDate() {
    const today = new Date();
    const nextWeek = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
    const nextWeekMonth = nextWeek.toLocaleString('default', {
      month: 'short',
    });

    return `${nextWeek.getDate()} ${nextWeekMonth}`;
  }

  function mapEmailToUser(userEmail, projectId, projectName) {
    const { firstName, lastName } = parseEmail(userEmail);
    return {
      person: {
        'email-address': userEmail,
        'user-name': userEmail,
        'first-name': firstName,
        'last-name': lastName,
        'isTempName': true,
        'company-id': account.value.companyId,
        'receiveDailyReports': true,
        'sendInvite': true,
        'getUserDetails': true,
        'adminOnTrialProjects': true,
        'canAccessTemplates': true,
        'canManageProjectTemplates': true,
        'canViewProjectTemplates': true,
        'canManageSchedule': true,
        'canViewSchedule': true,
        'canAccessPortfolio': true,
        'canManagePortfolio': true,
        'canManageCustomFields': true,
        'canAddProjects': true,
        'canAccessCalendar': false,
        'inviteType': 'trialUsers',
        'accessProjectIds': projectId,
        'sendInviteWithMessage': t("You've been added to {projectName}.", {
          projectName,
        }),
      },
      createCollaboratorIfNoSeats: true,
    };
  }

  async function inviteUsersToProject(state, projectId) {
    const { emails } = state[STEP_INVITE_PEOPLE];
    const { name } = state[STEP_PROJECT_NAME] ?? state[STEP_PROJECT_TYPE_SELECTION];

    const inviteUserList = emails
      .filter((email) => email.length)
      .map((email) => sendInvite(mapEmailToUser(email, projectId, name)));

    return Promise.allSettled(inviteUserList);
  }

  async function prioritizeFinanceTabForProject(projectId) {
    if (!projectId) {
      return;
    }

    projectIdToPrioritizeFinanceTab.value = projectId;
    await until(featureOrderLoaded, true);

    if (!featureOrder.value) {
      return;
    }

    const { id, projectId: _, installationId, numVisibleTabs, ...localOrder } = featureOrder.value;
    const sortedOrder = Object.entries(localOrder)
      .sort(([, positionA], [, positionB]) => positionA - positionB)
      .map(([feature]) => feature);
    const financeTabId = 'finance';
    const desiredFinanceTabPosition = 3;
    const currentFinanceTabPostion = sortedOrder.indexOf(financeTabId);

    if (desiredFinanceTabPosition === currentFinanceTabPostion) {
      return;
    }

    sortedOrder.splice(currentFinanceTabPostion, 1);
    sortedOrder.splice(desiredFinanceTabPosition, 0, financeTabId);

    const updatedOrder = Object.fromEntries(sortedOrder.map((feature, position) => [feature, position]));
    await updateProjectFeatureOrder(projectId, updatedOrder);
  }

  async function setAccountCurrency(state) {
    if (state[STEP_BILLING_TYPE]?.skipped) {
      return;
    }

    const currencyIdToBeSet = state[STEP_SET_BUDGET]?.currencyId ?? state[STEP_SET_TEAM_RATES]?.currencyId;
    if (!currencyIdToBeSet) {
      return;
    }

    if (account.value?.currency?.id === currencyIdToBeSet) {
      return;
    }

    await updateAccount({
      currencyId: currencyIdToBeSet,
    });
  }

  async function setBudget(state, project) {
    if (!project) {
      return;
    }

    if (state[STEP_BILLING_TYPE]?.type === BILLING_TYPE_NO_BUDGET) {
      return;
    }

    const {
      skipped,
      type: budgetType,
      amount,
      isRepeating,
      repeatPeriod,
      repeatUnit,
      addUnspentHours,
      subtractOverspentHours,
      endDateTime,
      startDateTime,
      timelogType,
      budgetCategory,
      profitMargin,
    } = state[STEP_SET_BUDGET];
    if (skipped) {
      return;
    }

    const isRetainer = state[STEP_BILLING_TYPE]?.type === BILLING_TYPE_RETAINER;
    const budgetPayload = {
      project,
      isRepeating,
      ...(isRepeating && { repeatPeriod }),
      ...(isRepeating && { repeatUnit }),
      capacity: amount * (budgetType === BUDGET_TYPE_TIME ? 60 : 100),
      type: budgetType,
      timelogType,
      isRetainer,
      ...(isRetainer && { carryUnderspend: addUnspentHours }),
      ...(isRetainer && { carryOverspend: subtractOverspentHours }),
      endDateTime: DateTime.fromISO(endDateTime),
      startDateTime: DateTime.fromISO(startDateTime),
      budgetCategory,
      ...(state[STEP_BILLING_TYPE]?.type === BILLING_TYPE_FIXED_FEE && { budgetProfitMargin: Number(profitMargin) }),
    };

    await createProjectBudget(budgetPayload);
  }

  function getRatesDataFromState(state) {
    const stepKeys = [STEP_SET_TEAM_RATES, STEP_INVITE_USERS_AND_SET_TEAM_RATES];
    for (const key of stepKeys) {
      const stepState = state[key];
      if (stepState && !stepState.skipped) {
        const { billableRates, costRates, hasAddedUsers, hasModifiedRates, projectId } = stepState;
        return {
          billableRates,
          costRates,
          skipped: false,
          hasAddedUsers,
          hasModifiedRates,
          projectId,
        };
      }
    }
    return { skipped: true };
  }

  async function setTeamRates(state, invitedUsers) {
    const { skipped, billableRates, costRates, hasModifiedRates, hasAddedUsers, projectId } =
      getRatesDataFromState(state);

    if (skipped) {
      return;
    }

    const users = [{ id: currentUser.value.id, position: 0 }, ...invitedUsers];

    const promises = (hasModifiedRates ? invitedUsers : users)
      .map((user, index) => {
        const billableRate = billableRates[hasModifiedRates ? index : user.position];
        const costRate = costRates[hasModifiedRates ? index : user.position];

        const userRatesPromises = [];
        if (billableRate) {
          const userRatePayload = {
            userId: user.id ?? user.userId,
            userRate: billableRate * 100,
          };
          if (user.projectId) {
            userRatePayload.projectId = user.projectId;
          }
          userRatesPromises.push(updateUserRate(userRatePayload));
        }

        if (costRate) {
          userRatesPromises.push(
            updateUserCost({
              userId: user.id ?? user.userId,
              userCost: costRate * 100,
            }),
          );
        }

        return userRatesPromises;
      })
      .flat();

    await Promise.allSettled(promises);

    if ((hasAddedUsers || promises.length > 0) && !onboardingTeamAddedOrRatedProjectIds.value.includes(projectId)) {
      onboardingTeamAddedOrRatedProjectIds.value = [...onboardingTeamAddedOrRatedProjectIds.value, projectId];
    }
  }

  function getTaskList(view) {
    return view === PREVIEW_TAB_BOARD
      ? [
          {
            id: 1,
            name: t('To do'),
            tasks: [
              {
                id: 1,
                name: '',
                assignedToUserId: -2,
                tags: t('Published'),
                priority: 1,
                startday: 1,
                dueDay: 1,
                dueDateDescription: t('Today'),
              },
              {
                id: 2,
                name: '',
                assignedToUserId: -2,
                tags: t('Draft'),
                priority: 100,
                startday: 1,
                dueDay: 8,
                dueDateDescription: nextWeekDueDate(),
              },
              {
                id: 3,
                name: '',
                assignedToUserId: 0,
                tags: t('Review'),
                priority: 200,
                startday: 1,
                dueDay: null,
                dueDateDescription: t('Not Set'),
              },
            ],
          },
          {
            id: 2,
            name: t('In progress'),
          },
          {
            id: 3,
            name: t('Completed'),
          },
        ]
      : [
          {
            id: 1,
            name: 'To do',
            tasks: [
              {
                id: 1,
                name: '',
                assignedToUserId: -2,
                tags: t('Published'),
                priority: 1,
                startday: 1,
                dueDay: 1,
                dueDateDescription: t('Today'),
              },
              {
                id: 2,
                name: '',
                assignedToUserId: -2,
                tags: t('Draft'),
                priority: 100,
                startday: 1,
                dueDay: 8,
                dueDateDescription: nextWeekDueDate(),
              },
              {
                id: 3,
                name: '',
                assignedToUserId: 0,
                tags: t('Review'),
                priority: 200,
                startday: 1,
                dueDay: null,
                dueDateDescription: t('Not Set'),
              },
            ],
          },
        ];
  }

  function createProjectJson(state) {
    const { name } = state[STEP_PROJECT_NAME] ?? state[STEP_PROJECT_TYPE_SELECTION];
    const { view } = state[STEP_SELECT_VIEW];
    const { tasksList } = state[STEP_ADD_TASKS];
    const { name: newCompany, clientProject } = state[STEP_ADD_CLIENT] || state[STEP_CLIENT_PROJECT] || {};
    const { columns, workflowName } = state[STEP_BOARD_COLUMNS] || {
      columns: [t('To do'), t('In progress'), t('Completed')],
      workflowName: '',
    }; // defaults added for now
    const boardColors = ['E557B3', 'FFC63C', '46B988'];
    const taskListBase = getTaskList(view);

    const project = {
      name,
      ...(clientProject && { newCompany }),
      tasksStartPage: view,
      startPage: view,
      tagsCollection: TAGS_COLLECTION,
      tasklists: [
        {
          ...taskListBase[0],
          name: t('My List'),
          tasks: tasksList.map((task, index) => ({
            ...taskListBase[0].tasks[index],
            columnName: taskListBase[0].name,
            name: task,
          })),
        },
      ],
    };

    const boardColumns = [
      ...columns.map((columnName, index) => ({
        name: columnName,
        color: boardColors[index % boardColors.length],
      })),
    ];

    if (onboardingFeatures.value.workflowsEnabled) {
      project.workflowStages =
        view === PREVIEW_TAB_BOARD
          ? [
              ...columns.map((column) => ({
                name: column.name,
                color: column.color?.replace('#', ''), // API expecting color without #
              })),
            ]
          : boardColumns;
    } else {
      project.boardColumns = boardColumns;
    }

    // If user has workflow name (in case of 'board' view)
    // Then we need to set workflow settings to make it a global workflow
    if (workflowName) {
      project.workflowSettings = {
        name: workflowName,
        isGlobal: true,
        isDefault: true,
      };
    }

    return project;
  }

  async function createProjectFromJson(state) {
    const createProjectResult = await createProjectFromJSON(JSON.stringify(createProjectJson(state), null, 2));

    return createProjectResult.projectId;
  }

  async function createProjectFromTemplate(state) {
    const { name: newCompany, clientProject } = state[STEP_ADD_CLIENT] || state[STEP_CLIENT_PROJECT] || {};

    const templatePayload = {
      'projectName': state[STEP_PROJECT_NAME].name,
      'activeView': state[STEP_SELECT_VIEW].view,
      'templateId': state[STEP_PROJECT_TYPE_SELECTION].selectedTemplate.id,
      ...(clientProject && { newCompany }),
      'cloneproject-action': 'copy',
      'cloneProjectName': state[STEP_PROJECT_NAME].name,
      'start-page': state[STEP_SELECT_VIEW].view,
    };

    const createProjectResult = await createProjectFromSampleTemplate(templatePayload);

    return createProjectResult.data.projectId;
  }

  async function createProject(state) {
    return onboardingFeatures.value?.templatesFlowEnabled &&
      state[STEP_PROJECT_TYPE_SELECTION]?.projectSelectedType === PROJECT_TYPE_TEMPLATE
      ? createProjectFromTemplate(state)
      : createProjectFromJson(state);
  }

  async function createDummyData(options) {
    trackExperimentA27();
    trackExperimentA27Variation();

    if (isExpA2420Variation.value && options.simplified) {
      const { sampleUsers, createdSampleClients } = await createSampleClientsAndUsers({ type: 'resources' });
      const sampleProjects = await createSampleProjects({ sampleUsers, createdSampleClients, type: 'resources' });
      await createSampleTentativeProjects({ sampleUsers, sampleProjects });
      await createAndAssignJobRoles({ sampleUsers });
      await assignUsersToTasks({ sampleProjects, sampleUsers });
    } else if (isExpA27Variation.value) {
      if (isExpA27V3Variation.value) {
        const { sampleUsers, createdSampleClients } = await createSampleClientsAndUsers();
        await createSampleProjects({ sampleUsers, createdSampleClients });
      } else {
        await createSampleProjects();
      }
    }
  }

  async function saveAsTemplate(projectId, { name, description, selectedClientId }) {
    const payload = {
      'id': projectId,
      'newFromTemplate': false,
      'targetDate': DateTime.now().toFormat('yyyymmdd'),
      'templateDateTarget': 'start',
      'sourceInstallationId': account.value.installationId,
      'cloneproject-action': 'copy',
      'cloneProjectName': name,
      description,
      'copyTasks': 'YES',
      'copyTasklists': 'YES',
      'copyMilestones': 'YES',
      'copyPeople': 'YES',
      'copyProjectRoles': 'YES',
      'createActivityLog': 'YES',
      'createItemsUsingCurrentUser': 'YES',
      'uncomplete': 'YES',
      'companyId': selectedClientId || account.value.companyId,
      'use-tasks': true,
      'use-milestones': true,
      'use-messages': true,
      'use-files': true,
      'use-time': true,
      'use-notebooks': true,
      'use-riskregister': false,
      'use-links': true,
      'use-finance': true,
      'use-comments': true,
      'use-forms': true,
      'category-id': 0,
      'toTemplate': true,
      'isBillable': true,
      'start-page': 'tasks',
      'overview-start-page': 'activity',

      /** NOTE: Below are fields where we can currently just take the default values, as they are the same
       * but this is an example of a default payload from the modal
       */

      // installationId: account.value.installationId,
      // description: '',
      // copyMessages: 'YES',
      // copyExpenses: 'YES',
      // copyAutomations: 'YES',
      // copyProjectPrivacy: 'YES',
      // copyFiles: 'YES',
      // copyLinks: 'YES',
      // copyNotebooks: 'YES',
      // copyTimelogs: 'NO',
      // copyInvoices: 'NO',
      // copyRisks: 'NO',
      // copyForms: 'YES',
      // copyComments: 'YES',
      // copyFollowers: 'YES',
      // copyProjectUpdates: 'NO',
      // copyLogo: 'YES',
      // copyBudgets: 'NO',
      // daysOffset: 0,
      // keepOffWeekends: 0,
      // tagIds: '',
      // customFields: '[]',
      // peopleIds: '387185',
      // projectOwnerId: 0,
      // templateRoleAssignees: '{}',
    };

    return cloneProject(payload).then(() => {
      if (!onboardingTemplateCreatedIds.value.includes(projectId)) {
        onboardingTemplateCreatedIds.value = [...onboardingTemplateCreatedIds.value, projectId];
      }
    });
  }

  async function createProjectGoalBased({ projectName, clientProject, selectedClientId, newClientName }) {
    let newClientId;
    // if we don't have a selected client id, then we need to create client first
    if (!selectedClientId && clientProject && newClientName) {
      const newClientPayload = {
        company: {
          name: newClientName,
        },
      };

      const newClient = await createClient(newClientPayload);
      newClientId = newClient.id;
    }

    const payload = {
      ...(clientProject && { companyId: Number(newClientId || selectedClientId) }),
      name: projectName,
      projectOwnerId: currentUser.value.id,
      people: currentUser.value.id,
    };

    return createProjectBasic(payload);
  }

  function redirectUserAfterCompletion(projectId) {
    if (!projectId || !(currentUser.value.isClientUser || currentUser.value.isCollaborator)) {
      router.push('/welcome/');
    } else {
      router.push(`/projects/${projectId}/`);
    }
  }

  async function redirectUserBasedOnSelectedGoal(selectedGoalId) {
    const setupPromises = [];
    switch (selectedGoalId) {
      default:
        activeProductTourId.value = true;
        showInsights.value = true;
        if (!newSchedulerInsights.value) {
          setupPromises.push(
            startFeatureTrial({
              name: 'newschedulerinsights',
              numDays: 30,
            }),
          );
        }
        await Promise.allSettled(setupPromises);
        firstTimeExperienceVisibility.value = {
          ...firstTimeExperienceVisibility.value,
          planningWorkload: false,
        };
        setTimeout(() => {
          activeProductTourId.value = null;
        }, 1000);
        router.push('/planning/workload');
    }
  }

  return {
    saveProductOutcomes,
    saveProductOutcomesBasedOnSelectedGoal,
    createProject,
    createProjectGoalBased,
    inviteUsersToProject,
    prioritizeFinanceTabForProject,
    setAccountCurrency,
    setBudget,
    setTeamRates,
    createDummyData,
    saveAsTemplate,
    redirectUserAfterCompletion,
    redirectUserBasedOnSelectedGoal,
  };
}

// TODO:
// Below is code from TWA onboarding that will need to be implemented at some point, but it either has to do with experiments or is not ready yet
// not needed until finance onboarding

//   if ([VIEW_TABLE, VIEW_LIST].includes(this.activeView)) {
//     this.$store.commit('layout/isSidebarCollapsed', true);
//   }

//   if (this.isUsingTemplatesFlow) {
//     this.isCreatingProject = true;
//     await this.getTemplateProgress();
//     this.isCreatingProject = false;
//   }
