<script>
import { DateTime, Interval } from 'luxon';
import { useI18n } from '@/util';

export const DATE_STEPPER_RANGE = {
  ALLTIME: 'allTime',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  QUARTER: 'quarter',
  QUARTERBYWEEK: 'quarterByWeek',
  QUARTERBYMONTH: 'quarterByMonth',
  CUSTOM: 'custom',
};

export function reportDateStepperRange(range) {
  switch (range) {
    case DATE_STEPPER_RANGE.QUARTERBYWEEK:
    case DATE_STEPPER_RANGE.QUARTERBYMONTH:
      return { [DATE_STEPPER_RANGE.QUARTER]: 1 };
    case DATE_STEPPER_RANGE.CUSTOM:
      return { [DATE_STEPPER_RANGE.WEEK]: 1 };
    default:
      return { [range]: 1 };
  }
}

export function reportDateStepperDateRange(range, date) {
  switch (range) {
    case DATE_STEPPER_RANGE.ALLTIME:
      return {
        startDate: '',
        endDate: '',
      };
    case DATE_STEPPER_RANGE.DAY:
      return {
        startDate: date.startOf('day'),
        endDate: date.endOf('day'),
      };
    case DATE_STEPPER_RANGE.WEEK:
      return {
        startDate: date.startOf('week', { useLocaleWeeks: true }),
        endDate: date.endOf('week', { useLocaleWeeks: true }),
      };
    case DATE_STEPPER_RANGE.MONTH:
      return {
        startDate: date.startOf('month'),
        endDate: date.endOf('month'),
      };
    case DATE_STEPPER_RANGE.QUARTER:
    case DATE_STEPPER_RANGE.QUARTERBYMONTH:
    case DATE_STEPPER_RANGE.QUARTERBYWEEK:
      return {
        startDate: date.startOf('quarter'),
        endDate: date.endOf('quarter'),
      };
    default:
      return {
        startDate: date.startOf('day'),
        endDate: date.startOf('day'),
      };
  }
}
</script>

<script setup>
const props = defineProps({
  ranges: {
    type: Array,
    required: true,
  },

  hidePrepend: {
    type: Boolean,
    required: false,
    default: false,
  },
  /**
   * The minimum date that can be selected.
   * @type {PropType<DateTime|undefined>}
   */
  minDate: {
    type: Object,
    default: undefined,
    validator: (date) => date == null || (DateTime.isDateTime(date) && date.isValid),
    required: false,
  },
  /**
   * The maximum date that can be selected.
   * @type {PropType<DateTime|undefined>}
   */
  maxDate: {
    type: Object,
    default: undefined,
    validator: (date) => date == null || (DateTime.isDateTime(date) && date.isValid),
    required: false,
  },
  trackPendoConfig: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['trackPendoEvent']);

const dates = defineModel('dates', {
  type: Object,
  required: true,
});

const { t, formatDateRange } = useI18n();

const hidePrependButton = computed(() => props.hidePrepend || dates.value.range === DATE_STEPPER_RANGE.ALLTIME);

const dateRanges = {
  [DATE_STEPPER_RANGE.ALLTIME]: t('All time'),
  [DATE_STEPPER_RANGE.DAY]: t('Day'),
  [DATE_STEPPER_RANGE.WEEK]: t('Week'),
  [DATE_STEPPER_RANGE.MONTH]: t('Month'),
  [DATE_STEPPER_RANGE.QUARTER]: t('Quarter'),
  [DATE_STEPPER_RANGE.QUARTERBYWEEK]: t('Quarter by week'),
  [DATE_STEPPER_RANGE.QUARTERBYMONTH]: t('Quarter by month'),
  [DATE_STEPPER_RANGE.CUSTOM]: t('Custom'),
};

const currentRangeOptions = {
  [DATE_STEPPER_RANGE.DAY]: { label: t('This day'), tooltip: t('Navigate to this day') },
  [DATE_STEPPER_RANGE.WEEK]: { label: t('This week'), tooltip: t('Navigate to this week') },
  [DATE_STEPPER_RANGE.MONTH]: { label: t('This month'), tooltip: t('Navigate to this month') },
  [DATE_STEPPER_RANGE.QUARTER]: { label: t('This quarter'), tooltip: t('Navigate to this quarter') },
  [DATE_STEPPER_RANGE.QUARTERBYWEEK]: { label: t('This quarter'), tooltip: t('Navigate to this quarter') },
  [DATE_STEPPER_RANGE.QUARTERBYMONTH]: { label: t('This quarter'), tooltip: t('Navigate to this quarter') },
  [DATE_STEPPER_RANGE.CUSTOM]: { label: t('Today'), tooltip: t('Navigate to today') },
};

function updateDates(value) {
  const prevValue = dates.value;

  dates.value = {
    ...prevValue,
    ...value,
  };
  if (value.range && value.range !== prevValue.range && props.trackPendoConfig.timePeriodApplied) {
    emit('trackPendoEvent', ...props.trackPendoConfig.timePeriodApplied, prevValue.range, value.range);
  }
}

function navigateToDateRange(range, date) {
  updateDates(reportDateStepperDateRange(range, date));
}

function navigateToPreviousDateRange() {
  if (props.trackPendoConfig.previousPeriodClicked) {
    emit('trackPendoEvent', ...props.trackPendoConfig.previousPeriodClicked);
  }
  const { endDate, range } = dates.value;

  navigateToDateRange(range, endDate.minus(reportDateStepperRange(range)));
}

function navigateToNextDateRange() {
  if (props.trackPendoConfig.nextPeriodClicked) {
    emit('trackPendoEvent', ...props.trackPendoConfig.nextPeriodClicked);
  }
  const { endDate, range } = dates.value;

  navigateToDateRange(range, endDate.plus(reportDateStepperRange(range)));
}

function navigateToCurrentDateRange() {
  if (props.trackPendoConfig.thisPeriodClicked) {
    emit('trackPendoEvent', ...props.trackPendoConfig.thisPeriodClicked);
  }

  navigateToDateRange(dates.value.range, DateTime.now());
}

async function updateRange(range) {
  if (range === DATE_STEPPER_RANGE.ALLTIME) {
    updateDates({
      range,
      startDate: '',
      endDate: '',
    });
  } else {
    updateDates({
      range,
      ...reportDateStepperDateRange(range, DateTime.now()),
    });
  }
}

function handleOpenTimePeriod() {
  if (props.trackPendoConfig.timePeriodOpened) {
    emit('trackPendoEvent', ...props.trackPendoConfig.timePeriodOpened);
  }
}

const options = computed(() =>
  props.ranges.map((key) => ({
    key,
    name: dateRanges[key],
  })),
);

const hideNavButton = computed(
  () => dates.value.range === DATE_STEPPER_RANGE.CUSTOM || dates.value.range === DATE_STEPPER_RANGE.ALLTIME,
);

const label = computed(() => {
  const { startDate, endDate } = dates.value;
  if (!startDate || !endDate) {
    return t('All time');
  }
  const start = DateTime.fromISO(startDate);
  const end = DateTime.fromISO(endDate);

  return formatDateRange(Interval.fromDateTimes(start, end), 'mini');
});

const prevLabel = computed(() => {
  const { range } = dates.value;

  switch (range) {
    case DATE_STEPPER_RANGE.DAY:
      return t('Previous day');
    case DATE_STEPPER_RANGE.WEEK:
      return t('Previous week');
    case DATE_STEPPER_RANGE.MONTH:
      return t('Previous month');
    case DATE_STEPPER_RANGE.QUARTER:
      return t('Previous quarter');
    default:
      return '';
  }
});

const nextLabel = computed(() => {
  const { range } = dates.value;

  switch (range) {
    case DATE_STEPPER_RANGE.DAY:
      return t('Next day');
    case DATE_STEPPER_RANGE.WEEK:
      return t('Next week');
    case DATE_STEPPER_RANGE.MONTH:
      return t('Next month');
    case DATE_STEPPER_RANGE.QUARTER:
      return t('Next quarter');
    default:
      return '';
  }
});

const customDates = computed({
  get() {
    const { startDate, endDate, range } = dates.value;
    if (range === DATE_STEPPER_RANGE.CUSTOM) {
      if (!startDate || !endDate || startDate === '' || endDate === '') {
        return [DateTime.now(), DateTime.now()];
      }
      return [startDate, endDate];
    }
    return [startDate, endDate];
  },
  set(next) {
    if (!next[0] || !next[1]) {
      return;
    }
    updateDates({
      range: DATE_STEPPER_RANGE.CUSTOM,
      startDate: next[0],
      endDate: next[1],
    });
  },
});

watch(
  () => dates.value,
  (crr, prev) => {
    const validDateObjects = crr.startDate instanceof DateTime && crr.endDate instanceof DateTime;
    if (
      (validDateObjects && !crr.startDate.hasSame(crr.endDate, 'day')) ||
      crr.range === prev?.range ||
      crr.range === DATE_STEPPER_RANGE.CUSTOM
    ) {
      return;
    }
    if (validDateObjects) {
      navigateToDateRange(crr.range, crr.startDate);
    }
  },
  {
    flush: 'sync',
    immediate: true,
  },
);
</script>

<template>
  <div class="flex items-center">
    <LscDateStepper>
      <template v-if="!hidePrependButton" #prepend>
        <LscDateStepperButton
          v-LsdTooltip="currentRangeOptions[dates.range]?.tooltip || ''"
          data-identifier="report-date-stepper"
          @click="navigateToCurrentDateRange"
        >
          <LscOverflowEllipsis>
            {{ currentRangeOptions[dates.range]?.label }}
          </LscOverflowEllipsis>
        </LscDateStepperButton>
      </template>
      <template v-if="!hideNavButton" #nav>
        <LscDateStepperNavButtons
          :prevLabel="prevLabel"
          :nextLabel="nextLabel"
          dataIdentifierPrefix="report-date-stepper-nav-buttons"
          @prev="navigateToPreviousDateRange"
          @next="navigateToNextDateRange"
        />
      </template>
      <template #default>
        <WidgetOptionsMenu location="bottom left">
          <template #activator="activator">
            <LscDateStepperLabel v-bind="activator.props" showDropDownIcon @click="handleOpenTimePeriod">
              {{ label }}
            </LscDateStepperLabel>
          </template>
          <WidgetOptionsMenuItem
            v-for="item in options"
            :key="item.key"
            class="truncate text-body-2"
            :text="item.name"
            :active="dates.range === item.key"
            @click="updateRange(item.key)"
          />
          <LscDatePicker
            v-model:dates="customDates"
            dataIdentifierPrefix="report-date-stepper-date-picker"
            :showShortcuts="false"
            :maxDate="maxDate"
            :minDate="minDate"
            :clearable="false"
          >
            <template #activator="activator">
              <WidgetOptionsMenuItem
                v-bind="activator.props"
                :active="dates.range === DATE_STEPPER_RANGE.CUSTOM"
                :text="t('Custom')"
                class="truncate text-body-2"
              />
            </template>
          </LscDatePicker>
        </WidgetOptionsMenu>
      </template>
    </LscDateStepper>
  </div>
</template>
