import * as TOKENS from '@teamwork/lightspeed-design-tokens';
import { useI18n } from '@/util';

/**
 * @typedef {Object} ColorObject
 * @property {string=} color
 * @property {string=} label
 */

export function useDefaultColorPickerColors() {
  const { t } = useI18n();

  /** @type {ColorObject} */
  const emptyColor = { color: null, label: t('Clear') };

  /** @type {ColorObject[]} */
  const defaultColors = [
    {
      color: TOKENS.LsdsCColorPickerColor1,
      label: t('Red'),
    },
    {
      color: TOKENS.LsdsCColorPickerColor2,
      label: t('Orange'),
    },
    {
      color: TOKENS.LsdsCColorPickerColor3,
      label: t('Yellow'),
    },
    {
      color: TOKENS.LsdsCColorPickerColor4,
      label: t('Green'),
    },
    {
      color: TOKENS.LsdsCColorPickerColor5,
      label: t('Deep blue'),
    },
    {
      color: TOKENS.LsdsCColorPickerColor6,
      label: t('Blue'),
    },
    {
      color: TOKENS.LsdsCColorPickerColor7,
      label: t('Teal'),
    },
    {
      color: TOKENS.LsdsCColorPickerColor8,
      label: t('Purple'),
    },
    {
      color: TOKENS.LsdsCColorPickerColor9,
      label: t('Pink'),
    },
    {
      color: TOKENS.LsdsCColorPickerColor10,
      label: t('Light purple'),
    },
    {
      color: TOKENS.LsdsCColorPickerColor11,
      label: t('Black'),
    },
    {
      color: TOKENS.LsdsCColorPickerColor12,
      label: t('Grey'),
    },
  ];

  return {
    defaultColors,
    emptyColor,
  };
}
