import { RECOMMENDED_ARTICLES_REGEX_MAPPING, RECOMMENDED_ARTICLES_STATIC_MAPPING, VALID_STATES } from './constants';

/**
 * Find subcategories (also nested ones) for given slug and knowledge base category data array.
 * @param {string} slug Category or subcategory slug
 * @param {KnowledgeBaseCategory[]} categories array of knowledge base category data
 * @returns {(KnowledgeBaseCategory[])} array of subcategories from given category/subcategory slug
 */
function getSubcategoriesFromSlug(slug, categories) {
  if (!Array.isArray(categories)) {
    return [];
  }

  for (const category of categories) {
    if (category.slug === slug) {
      return category.subCategories;
    }

    const nested = getSubcategoriesFromSlug(slug, category.subCategories);
    if (nested?.length > 0) {
      return nested;
    }
  }

  return [];
}

/**
 * Get a list of both the available sub-categories for given parent, and the articles in it
 * @param {string} categorySlug slug of parent category
 * @returns { Array<KnowledgeBaseCategory[] | KnowledgeBaseListArticle[]> | null } Object with concatenated arrays of sub-categories and articles, null when both of them are empty (for LswLoaderState logic)
 */
export function getSubcategoriesAndArticlesBySlug(categorySlug, categories, articles) {
  const subcategories =
    getSubcategoriesFromSlug(categorySlug, categories)
      ?.filter((sc) => sc.numArticles > 0) // hide categories with no articles
      ?.map((subcategory) => ({ ...subcategory, icon: 'lsi-project', type: 'subcategory' })) ?? [];

  if (!articles?.length) {
    return subcategories;
  }

  return subcategories.concat(articles);
}

export function validState(state) {
  return VALID_STATES.includes(state) ? state : VALID_STATES[0];
}

function extractDataFromKnowledgeBaseArticleURL(articleURL) {
  let url;
  try {
    url = new URL(articleURL);
  } catch (error) {
    return undefined;
  }

  if (url.host !== 'support.teamwork.com') {
    return undefined;
  }

  const splitURLPath = url.pathname.split('/');
  if (splitURLPath.length !== 4) {
    return undefined;
  }

  return {
    categorySlug: splitURLPath[splitURLPath.length - 2],
    articleSlug: splitURLPath[splitURLPath.length - 1],
  };
}

export function getRecommendedArticleDataForPath(path) {
  const recommendedArticleURL =
    RECOMMENDED_ARTICLES_STATIC_MAPPING[path] ||
    RECOMMENDED_ARTICLES_REGEX_MAPPING.find(([regex]) => regex.test(path))?.[1];

  if (!recommendedArticleURL) {
    return undefined;
  }

  const data = extractDataFromKnowledgeBaseArticleURL(recommendedArticleURL);
  if (!data) {
    return undefined;
  }

  return {
    ...data,
    isRecommended: true,
  };
}
