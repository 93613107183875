<script setup>
import { sanitizeUrl } from '@/util';

const props = defineProps({
  /**
   * The label to display.
   */
  label: {
    type: String,
    default: null,
  },
  /**
   * The main text to display.
   */
  text: {
    type: String,
    default: null,
  },
  /**
   * The internal link to display.
   * @type {PropType<import('vue-router').RouteLocationRaw>}
   */
  to: {
    type: String,
    default: undefined,
  },
  /**
   * The external link to display.
   */
  href: {
    type: String,
    default: undefined,
  },
  /**
   * The target of the link.
   */
  target: {
    type: String,
    default: '_blank',
  },
});

const LscEntityBannerVariantStyleConfig = tv({
  base: 'flex items-center gap-2 rounded-md bg-surface-default px-3 py-2 text-body-1',
  slots: {
    label: 'text-subtle',
    defaultSlot: 'flex min-w-0 flex-auto',
    text: '',
    prependSlot: 'flex items-center gap-2',
    appendSlot: 'flex items-center gap-2',
  },
  variants: {
    hasLink: {
      true: {
        text: 'inline-flex truncate text-primary no-underline hover:underline',
      },
      false: {
        text: 'text-default',
      },
    },
  },
});

const classes = computed(() =>
  LscEntityBannerVariantStyleConfig({
    hasLink: Boolean(props.to || props.href),
  }),
);
</script>

<template>
  <div :class="classes.base()">
    <div v-if="$slots.prepend || label" :class="classes.prependSlot()">
      <!-- @slot prepend -->
      <slot name="prepend">
        <span :class="classes.label()">{{ label }}</span>
      </slot>
    </div>
    <div :class="classes.defaultSlot()">
      <!-- @slot default -->
      <slot name="default">
        <RouterLink v-if="to" :to="to" :target="target" :class="classes.text()">
          <LscOverflowEllipsis>
            {{ text }}
          </LscOverflowEllipsis>
        </RouterLink>
        <a v-else-if="href" :href="sanitizeUrl(href)" :target="target" :class="classes.text()">
          <LscOverflowEllipsis>
            {{ text }}
          </LscOverflowEllipsis>
        </a>
        <LscOverflowEllipsis v-else :class="classes.text()">{{ text }}</LscOverflowEllipsis>
      </slot>
    </div>
    <div v-if="$slots.append" :class="classes.appendSlot()">
      <!-- @slot append -->
      <slot name="append" />
    </div>
  </div>
</template>
