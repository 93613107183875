<script setup>
import { DateTime } from 'luxon';
import { useI18n } from '@/util';
import ReportDateStepper, { DATE_STEPPER_RANGE } from '../../../ReportDateStepper.vue';
import ReportCustomReportPermissions from '../../permissions/ReportCustomReportPermissions.vue';
import { useReportCustomBuilder } from '../useReportCustomBuilder.js';
import ReportCustomBuilderOptionsColumns from './ReportCustomBuilderOptionsColumns.vue';
import ReportCustomBuilderOptionsMilestoneFilters from './ReportCustomBuilderOptionsMilestoneFilters.vue';
import ReportCustomBuilderOptionsProjectFilters from './ReportCustomBuilderOptionsProjectFilters.vue';
import ReportCustomBuilderOptionsTaskFilters from './ReportCustomBuilderOptionsTaskFilters.vue';
import ReportCustomBuilderOptionsUserFilters from './ReportCustomBuilderOptionsUserFilters.vue';

defineProps({
  validation: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['handleCustomReportTrack']);

const { t, formatDateTime } = useI18n();

const { currentReport, currentReportDateRange, clearCharts, reportTypes, hasInteractedWithReportBuilder } =
  useReportCustomBuilder();
const ranges = [DATE_STEPPER_RANGE.DAY, DATE_STEPPER_RANGE.WEEK, DATE_STEPPER_RANGE.MONTH, DATE_STEPPER_RANGE.QUARTER];

const reportTypesList = Object.values(reportTypes).map(({ value: title, key: value, icon }) => ({
  title,
  value,
  icon,
}));

const minCustomDate = DateTime.now().minus({ years: 2 });

const toggleConfimRemoveChartDialog = shallowRef(false);
const typeSelectMenuOpen = shallowRef(false);
const hasWarnedAboutCharts = shallowRef(false);
const pendingTypeChange = shallowRef(null);
const previousType = shallowRef(currentReport.value.type);
const localSelectedType = shallowRef(reportTypes[currentReport.value.type].key);

const trackPendoConfig = {
  timePeriodApplied: ['custom_reports', 'custom_report_date_range_applied', 'activation'],
  timePeriodOpened: ['custom_reports', 'custom_report_date_ranges_viewed', 'activation'],
  addUsersViewed: ['custom_reports', 'custom_report_add_users_viewed', 'activation'],
  usersAdded: ['custom_reports', 'custom_report_users_added', 'activation'],
};

function handleCustomReportTrack(pageType, eventAction, eventCategory, ...args) {
  emit('handleCustomReportTrack', pageType, eventAction, eventCategory, ...args);
}

function selectNewType(item) {
  if (item.value === currentReport.value.type) {
    typeSelectMenuOpen.value = false;
    return;
  }
  if (currentReport.value.charts.length > 0 && !hasWarnedAboutCharts.value) {
    pendingTypeChange.value = item.value;
    toggleConfimRemoveChartDialog.value = true;
  } else {
    applyTypeChange(item.value);
  }
}

function applyTypeChange(newType) {
  currentReport.value.type = newType;
  clearCharts.value = true;
  hasWarnedAboutCharts.value = true;
  typeSelectMenuOpen.value = false;
  previousType.value = newType;
  localSelectedType.value = reportTypes[newType].key;
}

function confirmChangeType() {
  if (pendingTypeChange.value) {
    applyTypeChange(pendingTypeChange.value);
    pendingTypeChange.value = null;
  }
  toggleConfimRemoveChartDialog.value = false;
}

function cancelChangeType() {
  currentReport.value.type = previousType.value;
  pendingTypeChange.value = null;
  toggleConfimRemoveChartDialog.value = false;
  typeSelectMenuOpen.value = false;
  localSelectedType.value = reportTypes[previousType.value].key;
}

watch(
  () => currentReport.value.name,
  (newName) => {
    if (newName === undefined) {
      currentReport.value.name = t('New report - {dateTime}', {
        dateTime: formatDateTime(DateTime.now(), 'short'),
      });
    }
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <div
    class="h-full w-fit"
    @mousedown="hasInteractedWithReportBuilder = true"
    @keydown="hasInteractedWithReportBuilder = true"
  >
    <div class="h-full overflow-scroll p-6">
      <VTextField
        v-model.trim="currentReport.name"
        :label="t('Report name')"
        :placeholder="currentReport.name"
        variant="outlined"
        density="compact"
        class="mb-6"
        data-identifier="report-builder--report-name"
        required
        :errorMessages="validation.currentReport.name?.$errors.map((error) => error.$message)"
        autofocus
      />
      <VSelect
        v-model="localSelectedType"
        v-model:menu="typeSelectMenuOpen"
        class="mb-6"
        variant="outlined"
        density="compact"
        :label="t('Type')"
        :items="reportTypesList"
        @click="emit('handleCustomReportTrack', 'custom_reports', 'custom_report_report_type_viewed', 'activation')"
      >
        <template v-if="reportTypes[currentReport.type].icon" #prepend-inner>
          <span class="mr-2 flex items-center">
            <LscIcon :icon="reportTypes[currentReport.type].icon" class="text-icon-subtle" size="sm" />
          </span>
        </template>
        <template #item="{ item }">
          <LscOptionsMenuItem
            :key="item.value"
            class="text-body-2"
            :text="item.title"
            :active="item.value === currentReport.type"
            @click="selectNewType(item)"
          >
            <template v-if="item.raw.icon" #prepend>
              <LscIcon :icon="item.raw.icon" class="text-icon-subtle" size="md" />
            </template>
          </LscOptionsMenuItem>
        </template>
      </VSelect>

      <h6 class="mb-1 text-body-1 font-semibold">{{ t('Date and filters') }}</h6>

      <div class="mb-6 flex justify-between font-semibold text-subtle">
        <ReportDateStepper
          v-model:dates="currentReportDateRange"
          :ranges="ranges"
          hidePrepend
          :minDate="minCustomDate"
          :trackPendoConfig="trackPendoConfig"
          @trackPendoEvent="handleCustomReportTrack"
        />

        <div class="flex items-center">
          <ReportCustomBuilderOptionsMilestoneFilters v-if="currentReport.type === 'milestone'" />
          <ReportCustomBuilderOptionsProjectFilters v-else-if="currentReport.type === 'project'" />
          <ReportCustomBuilderOptionsTaskFilters v-else-if="currentReport.type === 'task'" />
          <ReportCustomBuilderOptionsUserFilters v-else-if="currentReport.type === 'user'" />
        </div>
      </div>

      <h6 class="mb-2 text-body-1 font-semibold">{{ t('Columns*') }}</h6>
      <ReportCustomBuilderOptionsColumns
        :validation="validation.hasColumns"
        @handleCustomReportTrack="handleCustomReportTrack"
      />
      <div class="my-4 flex items-center">
        <LscSwitch v-model="currentReport.summary" :label="t('Show summary row')" class="mr-2 text-body-2" />
        <LscIcon
          v-LsdTooltip="t('Display totals/averages. Only columns with numeric values can be summed')"
          icon="lsi-tooltip"
          class="text-icon-subtle"
        />
      </div>
      <h6 class="mb-2 text-body-1 font-semibold">{{ t('Permissions') }}</h6>
      <div class="mb-4 flex items-center gap-1 text-body-1 text-subtle">
        <LscIcon icon="lsi-file-locked" size="md" class="mx-1" />
        <span>{{ t('Admins') }}</span>
        <LscIcon
          v-LsdTooltip="t('Site admins can see reports by default')"
          icon="lsi-tooltip"
          class="ml-auto"
          size="sm"
        />
        <span>{{ t('Can view and edit') }}</span>
      </div>
      <ReportCustomReportPermissions
        :report="currentReport"
        :trackPendoConfig="trackPendoConfig"
        @handleCustomReportTrack="handleCustomReportTrack"
      />
    </div>
    <LscDialogConfirm
      v-model="toggleConfimRemoveChartDialog"
      :title="t('Delete report')"
      :message="
        t(
          'Changing the report type will remove all charts currently added to this report. This action cannot be reversed and will take effect after you save your changes. Are you sure you want to proceed?',
        )
      "
      :cancelButtonText="t('No, leave as is')"
      :confirmButtonText="t('Yes, change type')"
      @confirm="confirmChangeType"
      @cancel="cancelChangeType"
    />
  </div>
</template>
