<script setup>
import { provideHubspot, provideIntegrationParagonAuth } from '@/api';
import { provideEmbeddedMode, provideTheaterMode } from '@/route';
import { provideDocumentTitle } from '@/util';
import { provideAppDrawerState } from '@/module/app';
import { provideBadge } from '@/module/badge';
import { provideCalendarGoogleSyncAuthPopup, provideCalendarGoogleSyncStatus } from '@/module/calendar';
import { provideHarvest } from '@/module/harvest';
import { HelpCenterSetup } from '@/module/helpCenter';
import { LegacySetup } from '@/module/legacy';
import { provideThemeColor } from '@/module/theme';
import { provideTimer } from '@/module/time';
import AppShellMain1 from './AppShellMain1.vue';
import { provideDarkMode } from './darkMode/useDarkMode';
import { provideAppShellSidebar } from './sidebar/useAppShellSidebar';

provideThemeColor();
provideTimer();
provideDocumentTitle();
provideAppShellSidebar();
provideAppDrawerState();
provideTheaterMode();
provideEmbeddedMode();
provideBadge();
provideHarvest();
provideCalendarGoogleSyncStatus();
provideCalendarGoogleSyncAuthPopup();
provideHubspot();
provideDarkMode();
provideIntegrationParagonAuth();
</script>

<template>
  <HelpCenterSetup>
    <LegacySetup>
      <AppShellMain1 />
    </LegacySetup>
  </HelpCenterSetup>
</template>
