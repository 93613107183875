<script setup>
const props = defineProps({
  value: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    default: null,
  },
  type: {
    type: String,
    default: 'field',
    validator: (value) => ['field', 'function'].includes(value),
  },
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['md', 'sm'].includes(value),
  },
  canEdit: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['click']);

function updateChip(event) {
  if (!props.canEdit) return;

  event.preventDefault();
  event.stopPropagation();
  emit('click', event);
}

const chipClasses = computed(() => {
  const baseClasses = 'inline-flex items-center rounded-lg';
  const sizeClasses = props.size === 'md' ? 'h-6 px-2 text-sm' : 'h-5 px-1.5 text-body-2';

  const typeClasses = props.type === 'function' ? 'bg-selected' : 'bg-surface-emphasis-selected';

  return `${baseClasses} ${sizeClasses} ${typeClasses}`;
});

const iconClasses = computed(() => {
  return props.type === 'function' ? 'text-icon-subtle mr-1' : 'text-primary mr-1';
});

const textClasses = computed(() => {
  return props.type === 'function' ? 'text-subtle' : 'text-on-emphasis';
});
</script>

<template>
  <span :class="chipClasses" @mousedown="updateChip">
    <LscIcon v-if="icon" :icon="icon" :class="[iconClasses, size === 'sm' ? 'text-sm' : '']" />
    <span :class="textClasses">{{ value }}</span>
  </span>
</template>

<style scoped>
.formula-chip {
  user-select: none;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
</style>
