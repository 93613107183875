<script setup>
import { useI18n } from '@/util';
import PreviewAvatar from './OnboardingWizardCommonPreviewAvatar.vue';

const props = defineProps({
  data: {
    type: Array,
    default: () => [
      {
        tasks: [
          { identifier: '1' },
          { identifier: '2' },
          { identifier: '3' },
          { identifier: '4' },
          { identifier: '5' },
          { identifier: '6' },
          { identifier: '7' },
          { identifier: '8' },
          { identifier: '9' },
        ],
      },
    ],
  },
  dataFromTemplate: {
    type: Boolean,
    default: true,
  },
});

const { t } = useI18n();

function getLabelByPriority(priority) {
  switch (priority) {
    case '1':
      return t('High');
    case '100':
      return t('Medium');
    case '200':
      return t('Low');
    default:
      return t('None');
  }
}

function getBackgroundColorByPriority(priority) {
  switch (priority) {
    case '1':
      return 'var(--onboarding-wizard-preview-priority-high-background)';
    case '100':
      return 'var(--onboarding-wizard-preview-priority-medium-background)';
    case '200':
      return 'var(--lsds-a-color-decorative-7)';
    default:
      return 'var(--lsds-a-color-surface-default)';
  }
}
</script>
<template>
  <table class="w-full table-fixed border-collapse">
    <thead>
      <tr class="h-10 border-b text-body-2 text-subtle">
        <th class="w-4/6 text-left font-normal">
          {{ t('Task name') }}
        </th>
        <th class="w-1/6 font-normal">
          {{ t('Assignee') }}
        </th>
        <th class="w-1/6 font-normal">
          {{ dataFromTemplate ? t('Priority') : t('Due date') }}
        </th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(tasklist, i) in data" :key="i">
        <tr v-if="tasklist.name" class="border-b">
          <td colspan="3">
            <div class="flex h-10 items-center gap-2">
              <LscIcon icon="lsi-accordion-expanded" size="sm" />
              <LscOverflowEllipsis class="text-left text-body-2 font-semibold text-default">
                {{ tasklist.name }}
              </LscOverflowEllipsis>
            </div>
          </td>
        </tr>
        <tr v-for="task in tasklist.tasks" :key="task.identifier" class="border-b text-body-1">
          <td>
            <div class="mr-1 flex h-10 items-center gap-2 overflow-x-hidden text-left">
              <LscIcon icon="lsi-teams-check-circle" size="sm" class="text-icon-subtle" />
              <LscOverflowEllipsis v-if="task.name" class="flex-1 font-normal text-default">
                {{ task.name }}
              </LscOverflowEllipsis>
              <LscSkeleton
                v-else
                :animation="false"
                :class="['h-[--lsds-a-typography-body-1-regular-font-size]', task.identifier % 2 ? 'w-60' : 'w-52']"
              />
            </div>
          </td>
          <td class="flex h-10 items-center justify-center border-x">
            <PreviewAvatar v-if="task.identifier && task.name" :id="task.identifier" class="block h-full w-full" />
            <div v-else class="size-5 rounded-full bg-action-secondary-default" />
          </td>
          <td class="h-10 p-0.5 text-center font-normal text-subtle">
            <div
              v-if="props.dataFromTemplate"
              class="flex h-full items-center justify-center rounded-sm text-default"
              :style="{ backgroundColor: getBackgroundColorByPriority(task.priority) }"
            >
              {{ getLabelByPriority(task.priority) }}
            </div>
            <LscOverflowEllipsis v-else-if="task.name" class="block">
              {{ task.due || t('Not set') }}
            </LscOverflowEllipsis>
            <div v-else class="flex h-[--lsds-a-typography-body-1-regular-line-height] items-center justify-center">
              <LscSkeleton :animation="false" class="h-[--lsds-a-typography-body-1-regular-font-size] w-20" />
            </div>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>
