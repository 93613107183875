<script setup>
import { NodeViewWrapper } from '@tiptap/vue-3';
import CustomfieldAddOrEditAdvancedFormulaChip from './CustomfieldAddOrEditAdvancedFormulaChip.vue';

const props = defineProps({
  node: {
    type: Object,
    required: true,
  },
});

function updateChip(event) {
  const chiptarget = event.currentTarget;
  const rect = chiptarget.getBoundingClientRect();
  const accuratePos = {
    top: rect.top,
    bottom: rect.bottom,
    left: rect.left,
    right: rect.right,
    width: rect.width,
    height: rect.height,
  };

  const customEvent = new CustomEvent('formula-chip-click', {
    detail: {
      pos: accuratePos,
      node: props.node.toJSON(),
    },
    bubbles: true,
  });
  event.currentTarget.dispatchEvent(customEvent);
}
</script>

<template>
  <NodeViewWrapper>
    <CustomfieldAddOrEditAdvancedFormulaChip
      :value="node.attrs.value"
      :icon="node.attrs.icon"
      :type="node.attrs.type || 'field'"
      :canEdit="true"
      @click="updateChip"
    />
  </NodeViewWrapper>
</template>
