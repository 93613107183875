import type { LscTabVariant } from './LscTabs.types.js';

type LscTabService = {
  modelValue: ModelRef<string | number>;
  variant: ComputedRef<LscTabVariant>;
  justified: ComputedRef<boolean>;
};

const symbol = Symbol('LscTabs') as InjectionKey<LscTabService>;

export function provideLscTabs(state: LscTabService) {
  provide(symbol, state);
}

export function useLscTabs() {
  const context = inject(symbol);

  if (!context) {
    throw new Error('<LscTab> must be used within <LscTabs>');
  }

  return context;
}
