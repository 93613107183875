<script setup>
import { unrefElement } from '@vueuse/core';
import { useI18n } from '@/util';
import LscMenu from '../menu/LscMenu.vue';

const props = defineProps({
  /**
   * The location of the menu.
   * @type {PropType<String>}
   */
  location: {
    type: String,
    default: 'bottom end',
  },
  /**
   * The icon to display.
   * @type {PropType<LscIconName|undefined>}
   */
  icon: {
    type: String,
    default: 'lsi-options',
  },
  /**
   * The activator element or selector.
   * @type {PropType<String|HTMLElement>}
   */
  activator: {
    type: [Object, String],
    default: undefined,
  },
  /**
   * Offset for the menu.
   * @type {PropType<number|string|number[]>}
   */
  offset: {
    type: [Number, String, Array],
    default: 12,
  },
});
const { t } = useI18n();

const activatorElement = computed(() => {
  if (!props.activator) {
    return undefined;
  }
  return typeof props.activator === 'string' ? document.querySelector(props.activator) : unrefElement(props.activator);
});
</script>

<template>
  <LscMenu :location :activator="activatorElement" origin="auto" :offset>
    <template v-if="!activator" #activator="activatorSlot">
      <slot name="activator" v-bind="activatorSlot">
        <LscIconButton
          v-LsdTooltip="t('Options')"
          v-bind="mergeProps($attrs, activatorSlot.props)"
          :ariaLabel="t('Options')"
          :icon="icon"
          size="sm"
          variant="primary"
          :ariaExpanded="activatorSlot.isActive"
          ariaHasPopup="menu"
        />
      </slot>
    </template>
    <VList v-bind="VListRounded">
      <slot />
    </VList>
  </LscMenu>
</template>
