<script setup>
import { customfieldEntities } from '../constants';

const props = defineProps({
  customfield: {
    type: Object,
    default: null,
  },
  /**
   * Which entity the customfield is for.
   * @type {PropType<customfieldEntities[keyof typeof customfieldEntities]>}
   */
  entity: {
    type: String,
    required: true,
    validator: (value) => Object.values(customfieldEntities).includes(value),
  },
  project: {
    type: Object,
    default: null,
  },
  canToggleProjectSpecific: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['created', 'deleted', 'updated']);

const CustomfieldAddOrEditDialogPanel = defineAsyncComponent(() => import('./CustomfieldAddOrEditDialogPanel.vue'));
</script>

<template>
  <LscDialog :retainFocus="false">
    <template #activator="activator"><slot name="activator" v-bind="activator" /></template>
    <template #default="{ close }">
      <CustomfieldAddOrEditDialogPanel
        v-bind="props"
        @close="close"
        @created="emit('created', $event)"
        @updated="emit('updated', $event)"
        @deleted="emit('deleted')"
      />
    </template>
  </LscDialog>
</template>
