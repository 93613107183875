import { usePendo } from '@/api';

/**
 * Provides functions for tracking Pendo events related to Reports.
 * Measurement plans:
 * https://digitalcrew.teamwork.com/spaces/product-analytics/page/42288-reports-phase-i
 * https://digitalcrew.teamwork.com/spaces/product-analytics/page/57354-utilisation
 * https://digitalcrew.teamwork.com/spaces/product-analytics/page/52100-profitability-report-phase-i
 */

export function useReportTracking() {
  const {
    trackPendoEvent,
    EVENT_CATEGORIES: { DISCOVERY, ADVANCED_DISCOVERY, ACTIVATION },
  } = usePendo();

  const COMMON_METRICS = ['plan_name', 'user_role', 'page', 'on_trial', 'is_icp', 'is_paid'];

  const categoryMap = {
    discovery: DISCOVERY,
    advanced: ADVANCED_DISCOVERY,
    activation: ACTIVATION,
  };

  function trackReportsEvent(pageType, eventAction, eventCategory, ...args) {
    const trackingData = {
      eventName: 'REPORTS_EVENT',
      commonMetrics: COMMON_METRICS,
      metadata: {
        page_type: pageType,
        event_action: eventAction,
        event_category: categoryMap[eventCategory],
      },
    };

    switch (eventAction) {
      case 'reports_viewed': {
        trackPendoEvent(trackingData);
        break;
      }
      case 'report_opened':
      case 'custom_report_created':
      case 'request_a_demo_clicked':
      case 'first_time_experience_viewed':
      case 'start_using_report_clicked': {
        const [reportType] = args;
        trackingData.metadata.report_type = reportType;
        trackPendoEvent(trackingData);
        break;
      }
      case 'utilisation_report_viewed': {
        const [defaultTimePeriod, defaultColumns, columnOrder] = args;
        trackingData.metadata.default_time_period = defaultTimePeriod;
        trackingData.metadata.default_columns = defaultColumns;
        trackingData.metadata.column_order = columnOrder;
        trackPendoEvent(trackingData);
        break;
      }
      case 'this_period_clicked':
      case 'previous_period_clicked':
      case 'next_period_clicked':
      case 'time_period_opened':
      case 'export_opened':
      case 'search_submitted': {
        const [defaultTimePeriod] = args;
        trackingData.metadata.default_time_period = defaultTimePeriod;
        trackPendoEvent(trackingData);
        break;
      }
      case 'report_exported': {
        const [exportType] = args;
        trackingData.metadata.export_type = exportType;
        trackPendoEvent(trackingData);
        break;
      }
      case 'time_period_applied': {
        const [, timePeriod] = args;
        trackingData.metadata.time_period = timePeriod;
        trackPendoEvent(trackingData);
        break;
      }
      case 'column_sorted':
      case 'column_added':
      case 'column_removed': {
        const [columnName] = args;
        trackingData.metadata.column_name = columnName;
        trackPendoEvent(trackingData);
        break;
      }
      case 'user_row_interaction': {
        const [actionType] = args;
        trackingData.metadata.action_type = actionType;
        trackPendoEvent(trackingData);
        break;
      }
      default:
        break;
    }
  }

  function trackCustomReportEvent(pageType, eventAction, eventCategory, ...args) {
    const trackingData = {
      eventName: 'CUSTOM_REPORT_EVENT',
      commonMetrics: COMMON_METRICS,
      report_type: 'custom_reports',
      metadata: {
        page_type: pageType,
        event_action: eventAction,
        event_category: categoryMap[eventCategory],
      },
    };
    switch (eventAction) {
      case 'create_custom_report_clicked':
      case 'edit_custom_report_clicked':
      case 'copy_custom_report_clicked':
      case 'delete_custom_report_clicked':
      case 'delete_custom_report_modal_viewed':
      case 'confirm_delete_clicked':
      case 'cancel_clicked':
      case 'export_custom_report_opened':
      case 'refresh_custom_report_clicked':
      case 'custom_report_searched':
      case 'schedule_custom_report_clicked':
      case 'create_new_report_clicked':
      case 'request_a_demo_clicked':
      case 'learn_more_clicked':
      case 'custom_report_report_type_viewed':
      case 'custom_report_add_columns_viewed':
      case 'custom_report_date_ranges_viewed':
      case 'custom_report_add_users_viewed':
      case 'discard_report_modal_viewed':
      case 'report_permissions_visible':
      case 'report_permissions_clicked':
      case 'report_permissions_modal_viewed':
      case 'add_users_modal_viewed':
      case 'copy_link_clicked':
      case 'visit_people_page_clicked':
      case 'discard_clicked': {
        trackPendoEvent(trackingData);
        break;
      }
      case 'custom_report_viewed': {
        const [customReportId, numberOfChartsVisible] = args;
        trackingData.custom_report_id = customReportId;
        trackingData.metadata.custom_report_id = customReportId;
        trackingData.metadata.number_of_charts_visible_on_report = numberOfChartsVisible;
        trackPendoEvent(trackingData);
        break;
      }
      case 'save_custom_report_clicked':
      case 'custom_report_modal_viewed': {
        const [type] = args;
        trackingData.metadata.type = type;
        trackPendoEvent(trackingData);
        break;
      }
      case 'custom_report_error_message_displayed': {
        const [errorMessage, type] = args;
        trackingData.metadata.error_message = errorMessage;
        trackingData.metadata.type = type;
        trackPendoEvent(trackingData);
        break;
      }
      case 'custom_report_exported': {
        const [fileType] = args;
        trackingData.metadata.file_type = fileType;
        trackPendoEvent(trackingData);
        break;
      }
      case 'report_exported': {
        const [exportType, reportId] = args;
        trackingData.metadata.export_type = exportType;
        trackingData.metadata.report_id = reportId;
        trackPendoEvent(trackingData);
        break;
      }
      case 'custom_report_created':
      case 'update_report_clicked': {
        const [reportType, dateRange, filtersApplied, usersAdded, type] = args;
        trackingData.metadata.report_type = reportType;
        trackingData.metadata.date_range = dateRange;
        trackingData.metadata.filters_applied = filtersApplied;
        trackingData.metadata.users_added = usersAdded;
        trackingData.metadata.type = type;
        trackPendoEvent(trackingData);
        break;
      }
      case 'custom_report_report_type_applied': {
        const [reportType] = args;
        trackingData.metadata.report_type = reportType;
        trackPendoEvent(trackingData);
        break;
      }
      case 'custom_report_date_range_applied': {
        const [dateRange] = args;
        trackingData.metadata.date_range = dateRange;
        trackPendoEvent(trackingData);
        break;
      }
      case 'custom_report_users_added':
      case 'users_added_to_report': {
        const [usersAdded] = args;
        trackingData.metadata.users_added = usersAdded;
        trackPendoEvent(trackingData);
        break;
      }
      default:
        break;
    }
  }

  function trackCustomReportChartEvent(reportId, pageType, eventAction, eventCategory, ...args) {
    const trackingData = {
      eventName: 'CUSTOM_REPORT_EVENT',
      commonMetrics: COMMON_METRICS,
      report_type: 'custom_reports',
      custom_report_id: reportId,
      metadata: {
        page_type: pageType,
        event_action: eventAction,
        event_category: categoryMap[eventCategory],
      },
    };

    switch (eventAction) {
      case 'add_chart_clicked':
      case 'chart_reordered':
      case 'chart_deleted':
      case 'delete_chart_modal_displayed': {
        trackPendoEvent(trackingData);
        break;
      }
      case 'chart_builder_modal_opened':
      case 'name_added':
      case 'search_submitted':
      case 'save_chart_clicked':
      case 'update_chart_clicked':
      case 'chart_updated': {
        const [isEditing] = args;
        trackingData.metadata.is_create_or_edit = isEditing;
        trackPendoEvent(trackingData);
        break;
      }
      case 'chart_type_selected': {
        const [type, isEditing] = args;
        trackingData.metadata.type = type;
        trackingData.metadata.is_create_or_edit = isEditing;
        trackPendoEvent(trackingData);
        break;
      }
      case 'add_data_clicked': {
        const [source, isEditing] = args;
        trackingData.metadata.source = source;
        trackingData.metadata.is_create_or_edit = isEditing;
        trackPendoEvent(trackingData);
        break;
      }
      case 'axis_toggled': {
        const [toggleType, col] = args;
        trackingData.metadata.toggle_type = toggleType;
        trackingData.metadata.axis_type = col;
        trackPendoEvent(trackingData);
        break;
      }
      case 'legend_toggled': {
        const [legendLabel] = args;
        trackingData.metadata.legend_name = legendLabel;
        trackPendoEvent(trackingData);
        break;
      }
      case 'compatible_data_clicked': {
        const [toggleType, isEditing] = args;
        trackingData.metadata.toggle_type = toggleType;
        trackingData.metadata.is_create_or_edit = isEditing;
        trackPendoEvent(trackingData);
        break;
      }
      case 'navigation_arrow': {
        const [direction, isEditing] = args;
        trackingData.metadata.navigation_direction = direction;
        trackingData.metadata.is_create_or_edit = isEditing;
        trackPendoEvent(trackingData);
        break;
      }
      case 'exit_clicked': {
        const [edits, isEditing] = args;
        trackingData.metadata.editsMade = edits;
        trackingData.metadata.is_create_or_edit = isEditing;
        trackPendoEvent(trackingData);
        break;
      }
      case 'exit_confirmation_modal_displayed': {
        const [cta, isEditing] = args;
        trackingData.metadata.cta_selected = cta;
        trackingData.metadata.is_create_or_edit = isEditing;
        trackPendoEvent(trackingData);
        break;
      }
      case 'error_displayed': {
        const [type, message, isEditing] = args;
        trackingData.metadata.error_type = type;
        trackingData.metadata.error_message = message;
        trackingData.metadata.is_create_or_edit = isEditing;
        trackPendoEvent(trackingData);
        break;
      }
      case 'chart_saved': {
        const [type, name, xAxis, yAxis, isEditing] = args;
        trackingData.metadata.type = type;
        trackingData.metadata.name = name;
        trackingData.metadata['x-axis'] = xAxis;
        trackingData.metadata['y-axis'] = yAxis;
        trackingData.metadata.is_create_or_edit = isEditing;
        trackPendoEvent(trackingData);
        break;
      }
      case 'charts_clicked': {
        const [numCharts, numVisibleCharts] = args;
        trackingData.metadata.number_of_active_charts = numCharts;
        trackingData.metadata.number_of_visible_charts = numVisibleCharts;
        trackPendoEvent(trackingData);
        break;
      }
      case 'chart_options_clicked': {
        const [type, source] = args;
        trackingData.metadata.type = type;
        trackingData.metadata.source = source;
        trackPendoEvent(trackingData);
        break;
      }
      case 'chart_toggled': {
        const [type] = args;
        trackingData.metadata.toggle_type = type;
        trackPendoEvent(trackingData);
        break;
      }
      default:
        break;
    }
  }

  function trackProfitabilityEvent(viewType, viewPeriod, pageType, eventAction, eventCategory, ...args) {
    const trackingData = {
      eventName: 'PROFITABILITY_EVENT',
      commonMetrics: COMMON_METRICS,
      metadata: {
        event_action: eventAction,
        event_category: categoryMap[eventCategory],
        page_type: pageType,
        view_type: viewType,
        view_period: viewPeriod,
      },
    };

    switch (eventAction) {
      case 'profitability_report_viewed':
      case 'row_expanded':
      case 'project_name_clicked':
      case 'set_rates_clicked':
      case 'set_owner_clicked':
      case 'project_owner_set':
      case 'this_period_clicked':
      case 'previous_period_clicked':
      case 'next_period_clicked':
      case 'time_period_opened':
      case 'projects_cta_clicked':
      case 'users_cta_clicked':
      case 'profit_as_percent_clicked':
      case 'profit_as_number_clicked':
      case 'export_opened':
      case 'search_submitted':
      case 'schedule_report_clicked':
      case 'customise_view_displayed':
        trackPendoEvent(trackingData);
        break;
      case 'column_sort_applied': {
        const [columnName] = args;
        trackingData.metadata.column_name = columnName;
        trackPendoEvent(trackingData);
        break;
      }
      case 'column_added':
      case 'column_removed': {
        const [columnTypes] = args;
        trackingData.metadata.column_types = columnTypes;
        trackPendoEvent(trackingData);
        break;
      }
      case 'time_period_applied': {
        const [prevViewPeriod, timePeriod] = args;
        trackingData.metadata.view_period = prevViewPeriod;
        trackingData.metadata.time_period = timePeriod;
        trackPendoEvent(trackingData);
        break;
      }
      case 'report_exported': {
        const [key] = args;
        const reportFormat = key === 'googleSheets' ? 'google_sheets' : key;
        trackingData.metadata.report_format = reportFormat;
        trackPendoEvent(trackingData);
        break;
      }
      default:
        break;
    }
  }

  function trackScheduleReportEvent(pageType, eventAction, eventCategory, ...args) {
    const trackingData = {
      eventName: 'REPORTS_EVENT',
      commonMetrics: COMMON_METRICS,
      metadata: {
        page_type: pageType,
        event_action: eventAction,
        event_category: categoryMap[eventCategory],
      },
    };

    switch (eventAction) {
      case 'scheduled_report_deleted':
      case 'update_scheduled_report_clicked':
      case 'scheduled_report_edited':
      case 'scheduled_report_downloaded':
      case 'delete_scheduled_report_modal_displayed':
      case 'edit_scheduled_report_modal_displayed':
        trackPendoEvent(trackingData);
        break;
      case 'scheduled_report_interacted': {
        const [actionType, contextValue] = args;
        trackingData.metadata.action_type = actionType;
        if (actionType === 'column_sorted') {
          trackingData.metadata.column_type = contextValue;
        }
        if (actionType === 'report_selected') {
          console.log('report_selected', contextValue);
          trackingData.metadata.report_type = contextValue;
        }
        trackPendoEvent(trackingData);
        break;
      }
      case 'manage_scheduled_reports_viewed': {
        const [numReports] = args;
        trackingData.metadata.number_of_scheduled_reports_total = numReports;
        trackPendoEvent(trackingData);
        break;
      }

      default:
        break;
    }
  }

  function trackFeatureTrialEvent(eventAction) {
    trackPendoEvent({
      eventName: 'FEATURE_TRIAL_EVENT',
      commonMetrics: ['plan_name', 'user_role', 'page'],
      metadata: {
        page_type: 'profitability_introduction',
        event_action: eventAction,
        fte_type: 'old_fte',
        trial_available: true,
      },
    });
  }

  function trackUpgradeSlateEvent(feature, eventAction, eventCategory) {
    trackPendoEvent({
      eventName: 'UPGRADE_SLATE_EVENT',
      commonMetrics: ['plan_name', 'user_role', 'page'],
      metadata: {
        page_type: 'upgrade_slate',
        event_action: eventAction,
        event_category: categoryMap[eventCategory],
        feature,
      },
    });
  }

  function trackUpgradePopoverEvent(feature, pageType, eventAction) {
    const trackingData = {
      eventName: 'FEATURE_ACCESS_EVENT',
      commonMetrics: COMMON_METRICS,
      feature: feature,
      metadata: {
        page_type: pageType,
        event_action: eventAction,
      },
    };
    switch (eventAction) {
      case 'feature_access_modal_displayed':
      case 'feature_access_modal_closed':
      case 'contact_us_to_upgrade_clicked':
      case 'learn_more_clicked':
        trackingData.metadata.reason_for_feature_access = 'limit_reached';
        trackPendoEvent(trackingData);
        break;
      default:
        break;
    }
  }

  return {
    trackCustomReportEvent,
    trackCustomReportChartEvent,
    trackReportsEvent,
    trackProfitabilityEvent,
    trackFeatureTrialEvent,
    trackScheduleReportEvent,
    trackUpgradePopoverEvent,
    trackUpgradeSlateEvent,
  };
}
