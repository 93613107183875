<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';
import { sanitizeUrl } from '@/util';
import LscOverflowEllipsis from '../../infodisplay/overflow/LscOverflowEllipsis.vue';
import { useLscTabs } from './useLscTabs.js';

const props = defineProps({
  /** The unique identifier for the tab. */
  dataIdentifier: {
    type: String,
    default: undefined,
  },
  /** Whether the tab is disabled. */
  disabled: {
    type: Boolean,
    default: false,
  },
  /** The icon to prepend to the label. */
  prependIcon: {
    type: String as PropType<LscIconName>,
    default: undefined,
  },
  /** The icon to append to the label. */
  appendIcon: {
    type: String as PropType<LscIconName>,
    default: undefined,
  },
  /** Used to set the item's active state. If `active` is not provided, the `value` prop will be used to determine the active state. */
  active: {
    type: Boolean,
    default: undefined,
  },
  /** The id of the tab, used for controlling the selected state */
  value: {
    type: String,
    default: undefined,
  },
  /* The external link to navigate to */
  href: {
    type: String,
    default: undefined,
    validator(value: string, { to }: { to: string | RouteLocationRaw }) {
      if (value && to) {
        warn('Only `href` or `to` should be provided');
        return false;
      }
      return true;
    },
  },
  /** The internal path to navigate to */
  to: {
    type: [String, Object] as PropType<RouteLocationRaw>,
    default: undefined,
    validator(value: RouteLocationRaw, { href }: { href: string }) {
      if (value && href) {
        warn('Only `href` or `to` should be provided');
        return false;
      }
      return true;
    },
  },
});

const emit = defineEmits<{
  click: [MouseEvent];
}>();

const componentToRender = computed(() => {
  if (props.to) {
    return 'RouterLink';
  }
  if (props.href) {
    return 'a';
  }
  return 'button';
});

const { variant, justified, modelValue } = useLscTabs();

const computedActive = computed(() => {
  if (props.active !== undefined) {
    return props.active;
  }
  if (props.value) {
    return modelValue.value === props.value;
  }
  return false;
});

function setActiveTab(event: MouseEvent) {
  if (props.disabled) {
    return;
  }
  emit('click', event);
  if (props.value) {
    modelValue.value = props.value;
  }
}

const iconSizes = {
  primary: 'md',
  secondary: 'sm',
  segmented: 'md',
} as const;

const iconSize = computed(() => iconSizes[variant.value]);

const lscTabVariantStyleConfig = tv({
  base: [
    'inline-flex content-center items-center justify-center overflow-hidden no-underline transition-colors',
    'focus:outline-focus-primary',
    'aria-disabled:cursor-not-allowed aria-disabled:font-normal',
  ],
  slots: {
    icon: 'shrink-0',
  },
  variants: {
    justified: {
      true: {
        base: 'flex-1',
      },
    },
    variant: {
      primary: {
        base: [
          '-mb-px min-h-8 gap-2 border-b px-4 text-subtle hover:text-primary focus-visible:text-primary',
          'aria-selected:border-primary-default aria-selected:font-medium aria-selected:text-primary',
          'aria-disabled:text-disabled',
        ],
      },
      secondary: {
        base: [
          'min-h-7 gap-1 rounded-md px-2 py-1 text-body-2 text-subtle',
          'hover:bg-surface-emphasis-hover hover:text-primary focus-visible:bg-surface-emphasis-hover focus-visible:text-primary',
          'aria-selected:bg-surface-emphasis-selected aria-selected:font-medium aria-selected:text-primary',
          'aria-disabled:text-disabled',
        ],
      },
      segmented: {
        base: [
          'transition-border flex min-h-7 gap-2 rounded-md border border-transparent px-4 text-subtle hover:border-bold focus-visible:border-bold',
          'aria-selected:border-bold aria-selected:bg-default aria-selected:font-medium',
          'aria-disabled:text-disabled',
        ],
      },
    },
  },
});

const classes = computed(() =>
  lscTabVariantStyleConfig({
    variant: variant.value,
    justified: justified.value,
  }),
);
</script>
<template>
  <Component
    :is="componentToRender"
    role="tab"
    :class="classes.base()"
    :type="componentToRender === 'button' ? 'button' : undefined"
    :aria-selected="computedActive"
    :aria-disabled="disabled"
    :disabled="disabled"
    :to="to"
    :href="sanitizeUrl(href)"
    :data-identifier="dataIdentifier"
    @click="setActiveTab"
  >
    <slot name="prepend">
      <LscIcon v-if="prependIcon" :size="iconSize" :icon="prependIcon" :class="classes.icon()" />
    </slot>
    <LscOverflowEllipsis>
      <slot />
    </LscOverflowEllipsis>
    <slot name="append">
      <LscIcon v-if="appendIcon" :size="iconSize" :icon="appendIcon" :class="classes.icon()" />
    </slot>
  </Component>
</template>
