import { until } from '@vueuse/core';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import { useRouter } from 'vue-router';
import { useQuickView } from '@/route';
import { useLegacyBridge } from '@/module/legacy';
import { useHighlightedTask } from './useHighlightedTask';

const useTaskDetailsSymbol = Symbol('useTaskDetails');

export function provideTaskDetails() {
  const router = useRouter();
  const { openQuickView, closeQuickView, quickViewItem } = useQuickView();
  const { activeTaskDetailsId } = useLegacyBridge();
  const { setHighlightedTask } = useHighlightedTask();

  const task = computed(() => quickViewItem.value);
  const taskLoaded = shallowRef(false);
  const taskMovementCounter = shallowRef(0);

  const topElement = shallowRef(undefined);
  const bottomElement = shallowRef(undefined);
  const attachmentsListElement = shallowRef(undefined);
  const historyElement = shallowRef(undefined);
  const commentListElement = shallowRef(undefined);
  const reminderElement = shallowRef(undefined);
  const dependenciesElement = shallowRef(undefined);
  const timelogsElement = shallowRef(undefined);
  const proofsElement = shallowRef(undefined);
  const subtaskInputElement = shallowRef(undefined);

  const commentItemElements = shallowRef({});
  const isSubtasksInputFocused = shallowRef(false);
  const isDependencyDialogOpen = shallowRef(false);

  const autofocusTaskName = shallowRef(false);
  const autofocusTaskDescription = shallowRef(false);

  const highlightedCommentId = shallowRef(undefined);

  // Selected tab in main task details area
  // e.g. details, dependencies, proofs, history
  // Depending on `projects-lightspeed-task-details-ui-variation` and `projects-replace-task-details-page`
  // LD flag, this can be different e.g. files, timelogs, comments
  const selectedTab = shallowRef('');
  // Selected tab in right hand sidebar if task details expanded
  // e.g. comments or activity
  const sidebarSelectedTab = shallowRef('comments');

  const sections = {
    comments: {
      element: commentListElement,
      children: commentItemElements,
    },
    files: {
      element: attachmentsListElement,
      options: { block: 'start' },
    },
    history: {
      element: historyElement,
    },
    reminders: {
      element: reminderElement,
    },
    dependencies: {
      element: dependenciesElement,
      options: { block: 'start' },
    },
    timelogs: {
      element: timelogsElement,
    },
    proofs: {
      element: proofsElement,
    },
    reply: {
      element: commentListElement,
    },
    subtasks: {
      element: subtaskInputElement,
    },
    top: {
      element: topElement,
      options: { block: 'start', behavior: 'instant' },
    },
    bottom: {
      element: bottomElement,
      options: { block: 'end' },
    },
  };

  const hash = computed(() => router.currentRoute.value?.hash.slice(1));

  function setHash(param) {
    router.replace({ hash: param ? `#${param}` : '' });
  }

  function clearTask() {
    activeTaskDetailsId.value = undefined;
    setHighlightedTask(undefined);
  }

  function closeTask() {
    clearTask();
    closeQuickView();
  }

  /**
   *
   * @param {object} params - The parameters for opening the task.
   * @param {object} params.task - The task to open.
   * @param {string} [params.hash] - (optional) The hash to be added to the url.
   * @param {boolean} [params.editTaskName] - (optional) Whether to focus the task name
   * @param {boolean} [params.editTaskDescription] - (optional) Whether to focus the task description
   */
  function openTask({ task: _task, hash: _hash, editTaskName, editTaskDescription }) {
    autofocusTaskName.value = editTaskName;
    autofocusTaskDescription.value = editTaskDescription;
    openQuickView(`/tasks/${_task.id}`, { hash: _hash, item: _task });
  }

  function scrollToElement(element, scrollOptions = {}) {
    if (element) {
      scrollIntoViewIfNeeded(element, {
        scrollMode: 'if-needed',
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
        ...scrollOptions,
      });
    }
  }

  function scrollToSection(section) {
    const { element, options } = sections[section];
    scrollToElement(element?.value, options);
  }

  async function scrollToComment(commentId, waitFor = false) {
    const commentElement = computed(() => {
      return commentItemElements.value[commentId];
    });

    const elementsExist = computed(() => {
      return Boolean(commentListElement.value) && Boolean(commentElement.value);
    });

    await until(elementsExist).toBeTruthy({ timeout: !waitFor ? 0 : 3000 });

    if (!(commentElement.value instanceof HTMLElement)) {
      return;
    }

    scrollToElement(commentElement.value, { block: 'nearest' });
  }

  function scrollToSectionDelayed(section) {
    const { element, options } = sections[section];
    setTimeout(() => scrollToElement(element?.value, options), 500);
  }

  const isOpen = computed(() => Boolean(task.value));
  const isDialogOpen = shallowRef(false);

  provide(useTaskDetailsSymbol, {
    // Read-only - use `openTask` to display the details of a different task
    task,
    openTask,
    closeTask,
    hash,
    taskLoaded,
    taskMovementCounter,
    setHash,
    clearTask,
    isSubtasksInputFocused,
    isDependencyDialogOpen,
    isOpen,
    isDialogOpen,
    scrollToSection,
    scrollToComment,
    topElement,
    bottomElement,
    attachmentsListElement,
    historyElement,
    commentListElement,
    commentItemElements,
    sections,
    reminderElement,
    subtaskInputElement,
    dependenciesElement,
    timelogsElement,
    proofsElement,
    scrollToSectionDelayed,
    autofocusTaskName,
    autofocusTaskDescription,
    selectedTab,
    sidebarSelectedTab,
    highlightedCommentId,
  });
}

export function useTaskDetails() {
  return inject(useTaskDetailsSymbol);
}
