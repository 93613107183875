<script setup>
import { useI18n } from '@/util';
import CustomfieldAddOrEditAdvancedFormulaChip from './CustomfieldAddOrEditAdvancedFormulaChip.vue';
import { useFormulaData } from './useFormulaData.js';

defineProps({
  option: {
    type: String,
    default: null,
  },
});

const { t } = useI18n();
const { parseSyntaxForUI } = useFormulaData();
</script>

<template>
  <div v-if="option" class="flex w-56 flex-col gap-3 p-4">
    <div class="flex items-center gap-2">
      <LscIcon :icon="option.icon || 'lsi-formula-function'" size="md" class="text-icon-subtle" />
      <span class="text-subtitle-2 font-semibold">{{ option.name }}</span>
    </div>

    <template v-if="option.type === 'function'">
      <span class="text-body-1">{{ option.description }}</span>
      <span class="text-body-1 font-semibold">{{ t('Syntax') }}</span>
      <div class="flex flex-wrap items-center gap-1">
        <template v-for="(element, index) in parseSyntaxForUI(option.syntax)" :key="index">
          <CustomfieldAddOrEditAdvancedFormulaChip
            v-if="element.type === 'chip'"
            :value="element.value"
            size="sm"
            :icon="element.icon"
            :type="element.chipType"
            class="w-fit"
          />
          <span v-else>{{ element.value }}</span>
        </template>
      </div>

      <span class="text-body-1 font-semibold">{{ t('Examples') }}</span>
      <LscCard />
    </template>

    <template v-if="option.type === 'field'">
      <span class="text-body-1">Decription of the field in easy to understand terms</span>
      <span class="text-body-1 font-semibold">{{ t("How it's calculated") }}</span>
      <span class="text-body-1">Billable time - Available time * 100</span>
    </template>

    <template v-if="option.type === 'operator'">
      <span class="text-body-1">{{ option.description }}</span>
      <span class="text-body-1 font-semibold">{{ t('Syntax') }}</span>
      <div class="flex flex-wrap items-center gap-1">
        <template v-for="(element, index) in parseSyntaxForUI(option.syntax)" :key="index">
          <CustomfieldAddOrEditAdvancedFormulaChip
            v-if="element.type === 'chip'"
            :value="element.value"
            size="sm"
            :icon="element.icon"
            :type="element.chipType"
            class="w-fit"
          />
          <span v-else>{{ element.value }}</span>
        </template>
      </div>
    </template>
  </div>
  <div v-else class="flex w-56 flex-col gap-3 p-4">
    <div class="flex items-center gap-2">
      <LscIcon icon="lsi-help" size="sm" class="text-icon-subtle" />
      <LscOverflowEllipsis class="font-semibold">{{ t('How to start?') }}</LscOverflowEllipsis>
    </div>
    <span>{{ t('Select or type a function, fields and operators.') }}</span>
    <span>
      {{ t('Make sure the syntax is correct - follow on-screen tips, and descriptions.') }}
    </span>
    <span class="text-subtle">
      {{ t('Tip: Use formula examples for a quick setup.') }}
    </span>
    <span class="text-body-1 font-semibold">{{ t('Examples') }}</span>
    <LscCard />
  </div>
</template>
