const symbol = Symbol('LswTagPicker');

/**
 * @typedef {import('../../../components/shared.types.js').Tag} Tag
 */

/**
 * @param {object} params
 * @param {ShallowRef<Tag[]>} options.selectedTags
 * @param {ComputedRef<number?>} options.projectId
 * @param {ComputedRef<boolean>} options.includeProjectTags
 */
function TagPicker({ selectedTags: _selectedTags, projectId: _projectId, includeProjectTags: _includeProjectTags }) {
  const selectedTags = shallowRef(_selectedTags);
  const projectId = shallowRef(_projectId);
  const includeProjectTags = shallowRef(_includeProjectTags);

  return {
    selectedTags,
    projectId,
    includeProjectTags,
  };
}

export function provideLswTagPicker(params) {
  const tagPicker = TagPicker(params);
  provide(symbol, tagPicker);
  return tagPicker;
}

/**
 * @returns {ReturnType<typeof TagPicker>}
 */
export function useLswTagPicker() {
  const lswTagPicker = inject(symbol);
  if (!lswTagPicker) {
    throw new Error('useLswTagPicker must be called within LswTagPicker');
  }
  return lswTagPicker;
}
