<script setup>
import useVuelidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { useSavedfilterActions } from '@/api';
import { useClipboard, useI18n, useValidators } from '@/util';

const props = defineProps({
  filter: {
    type: Object,
    default: undefined,
  },
});
const emit = defineEmits(['close']);

const { shareSavedfilter } = useSavedfilterActions();
const { required } = useValidators();
const { t } = useI18n();
const toast = useLsToast();
const { copyToClipboard } = useClipboard();

function close() {
  emit('close');
}

// eslint-disable-next-line lightspeed/prefer-shallow-ref
const users = ref([]);
const v$ = useVuelidate(
  {
    users: {
      required: helpers.withMessage(() => t('Please select at least one user to share the filter with'), required),
    },
  },
  { users },
);

const shareTitle = computed({
  get() {
    return props.filter.sharedState === 1;
  },
  async set(value) {
    shareSavedfilter(props.filter, {
      savedFilter: {
        sharedState: value ? 1 : 0,
      },
      updateDefault: true,
    });
  },
});

const isSubmitting = shallowRef(false);
async function save() {
  try {
    v$.value.$touch();
    if (v$.value.$error) {
      return;
    }
    isSubmitting.value = true;

    await shareSavedfilter(props.filter, {
      includeDetails: 0,
      shareToUsers: users.value.map((user) => user.id).join(','),
    });
    toast.success(t('Shared filter sent successfully'));
    close();
  } finally {
    isSubmitting.value = false;
  }
}

function copyLink() {
  copyToClipboard(props.filter.shareLink);
}
</script>

<template>
  <LscDialog>
    <LscDialogCard size="xs" :title="t('Share filter')" @close="close">
      <LscForm id="FilterShareDialog" class="grid auto-rows-auto gap-4" @submit="save">
        <LscEntityBanner :label="t('Filter')" :text="filter.title" :href="filter.shareLink">
          <template #append>
            <LscIconButton
              v-LsdTooltip="t('Copy filter link')"
              icon="lsi-copy"
              :ariaLabel="t('Copy filter link')"
              size="sm"
              @click="copyLink"
            />
          </template>
        </LscEntityBanner>

        <LscSwitch v-model="shareTitle">
          <template #label>
            {{ t('Share filter title') }}
            <LscIcon
              v-LsdTooltip="t('This will disclose the title of the filter with the users you share it with')"
              icon="lsi-tooltip"
              size="sm"
              class="text-icon-subtle"
            />
          </template>
        </LscSwitch>

        <div class="grid auto-rows-auto gap-2">
          <label class="font-semibold">{{ t('Share filter via email') }}</label>
          <LswAssigneePicker v-model:assignees="users" :includeTeams="false" />

          <LscMessage v-if="v$.users.$error" variant="critical">
            {{ v$.users.$errors[0].$message }}
          </LscMessage>
        </div>
      </LscForm>
      <template #actions>
        <div class="mt-3 flex min-h-10 w-full justify-between p-0">
          <LscButton variant="tertiary" @click="close">Close</LscButton>
          <LscButton type="submit" :loading="isSubmitting" variant="primary" form="FilterShareDialog">
            {{ t('Share') }}
          </LscButton>
        </div>
      </template>
    </LscDialogCard>
  </LscDialog>
</template>
