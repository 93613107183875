<script setup lang="ts">
import { type LscTabVariant, LscTabVariants } from './LscTabs.types.js';
import { provideLscTabs } from './useLscTabs';

const props = defineProps({
  /** The variant of the tabs. */
  variant: {
    type: String as PropType<LscTabVariant>,
    default: 'primary',
    validator: (value: LscTabVariant) => LscTabVariants.includes(value),
  },
  /** Whether the tabs should be stretched to fill the container. Only use this when the tab count is 5 or less. */
  justified: {
    type: Boolean,
    default: false,
  },
});

const modelValue = defineModel('modelValue', {
  type: String,
  default: undefined,
});

provideLscTabs({
  modelValue,
  variant: computed(() => props.variant),
  justified: computed(() => props.justified),
});

const lscTabsVariantStyleConfig = tv({
  base: 'inline-flex items-center',
  variants: {
    variant: {
      primary: 'border-b border-b-separator',
      secondary: 'gap-1',
      segmented: 'gap-1 rounded-md bg-surface-default p-0.5',
    },
  },
});

const classes = computed(() => lscTabsVariantStyleConfig(props));
</script>
<template>
  <nav class="LscTabs" role="tablist" :class="classes">
    <slot />
  </nav>
</template>
