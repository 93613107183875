<script setup>
import { useDebounceFn } from '@vueuse/core';
import { useLoadingCount, useProjectsV3Loader } from '@/api';
import { useI18n } from '@/util';
import PickersNoProjectsFound from '../../../assets/PickersNoProjectsFound.svg';
import PickersNoStarredProjectsFound from '../../../assets/PickersNoStarredProjectsFound.svg';
import LscSkeleton from '../../../components/infodisplay/skeleton/LscSkeleton.vue';
import LswLoaderTrigger from '../../loader/LswLoaderTrigger.vue';
import { LswProjectPickerModes, LswProjectPickerTabs } from './constants.js';

const props = defineProps({
  /**
   * Whether the input should be focused when the component is mounted.
   */
  autofocus: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the input is disabled.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * The label for the input.
   */
  label: {
    type: String,
    default: undefined,
  },
  /**
   * The mode of the input.
   * @type {PropType<typeof LswProjectPickerModes[number]>}
   */
  mode: {
    type: String,
    default: 'count',
    // only allow mode if it's multiple
    validator: (value, { multiple }) => !multiple || LswProjectPickerModes.includes(value),
  },
  /**
   * The items to prepend to the list of projects.
   * @type {PropType<{ id: number; name: string }[]>}
   */
  prependItems: {
    type: Array,
    default: () => [],
  },
  /**
   * Extra params to pass to the useProjectsV3Loader
   */
  loaderParams: {
    type: Object,
    default: () => ({}),
  },
  /**
   * The label tooltip text to display within the autocomplete.
   */
  labelTooltip: {
    type: String,
    default: '',
  },
  /**
   * Whether the input is in an error state.
   */
  error: {
    type: Boolean,
    default: false,
  },
  /**
   * A list of error messages.
   * @type {PropType<string[]>}
   */
  errorMessages: {
    type: Array,
    default: () => [],
  },
  /**
   * Whether the input should allow multiple selections.
   * @type {PropType<boolean>}
   */
  multiple: {
    type: Boolean,
    default: false,
  },
  /**
   * The tabs to display in the input menu.
   * @type {PropType<typeof LswProjectPickerTabs[number]>[]}
   */
  tabs: {
    type: Array,
    default: () => ['starred', 'all'],
    validator: (value) => value.every((tab) => LswProjectPickerTabs.includes(tab)) && value.length !== 1,
  },
  /**
   * The data identifier prefix used for Pendo/testing
   */
  dataIdentifierPrefix: {
    type: String,
    default: undefined,
  },
  /**
   * Additional project fields
   * @type {PropType<string[]>}
   */
  extraFields: {
    type: Array,
    default: () => [],
  },
  disableProject: {
    type: Function,
    default: () => false,
  },
});

const modelValue = defineModel({
  type: [Object, Array],
  default: undefined,
});

const { t } = useI18n();

const autocompleteRef = shallowRef(null);
const searchTerm = shallowRef('');
const pageSize = 20;
const count = shallowRef(-1);
const selectedTabId = shallowRef(props.tabs[0] ?? 'all');
const search = useDebounceFn((val) => {
  searchTerm.value = val === props.modelValue?.name ? '' : val.trim();
}, 250);

const displayedTabs = computed(() => {
  if (props.tabs.length === 1) {
    // eslint-disable-next-line no-console
    console.warn('LswProjectPicker: "tabs" should contain at least 2 elements');
    return [];
  }
  return [
    {
      id: 'recent',
      label: t('Recent'),
      dataIdentifier: props.dataIdentifierPrefix
        ? `${props.dataIdentifierPrefix}-project-picker-recent-tab`
        : undefined,
    },
    {
      id: 'starred',
      label: t('Starred'),
      dataIdentifier: props.dataIdentifierPrefix
        ? `${props.dataIdentifierPrefix}-project-picker-starred-tab`
        : undefined,
    },
    {
      id: 'all',
      label: t('All'),
      dataIdentifier: props.dataIdentifierPrefix ? `${props.dataIdentifierPrefix}-project-picker-all-tab` : undefined,
    },
  ].filter(({ id }) => props.tabs.includes(id));
});

const tabsParams = computed(() => {
  switch (selectedTabId.value) {
    case 'recent': {
      return {
        orderBy: 'lastWorkedOn',
        orderMode: 'desc',
      };
    }
    case 'starred': {
      return {
        onlyStarredProjects: true,
        orderBy: 'companyname',
        orderMode: 'asc',
      };
    }
    default: {
      return {
        orderBy: 'companyname',
        orderMode: 'asc',
      };
    }
  }
});

const defaultFields = ['id', 'name', 'isStarred', 'companyId', 'allowNotifyAnyone', 'notifyTaskAssignee', 'isBillable'];

function toggleMenu(opened) {
  if (!opened || count.value >= 0) {
    return;
  }
  count.value = pageSize;
}

const state = useProjectsV3Loader({
  count,
  pageSize,
  params: computed(() => ({
    'searchTerm': searchTerm.value,
    'includeProjectUserInfo': true, // needed for isStarred
    'fields[projects]': defaultFields.concat(props.extraFields).join(','),
    'onlyProjectsWithExplicitMembership': true,
    'searchCompanies': true,
    ...props.loaderParams,
    ...tabsParams.value,
    'include': (props.loaderParams.include?.split(',') ?? []).concat('companies').join(','),
  })),
});
const { loaded, inSync } = state;

const showTabs = computed(() => displayedTabs.value.length > 0);

const shouldShowCompanyHeaders = computed(() => selectedTabId.value === 'all');

const selectionText = computed(() => {
  if (modelValue.value.length > 1 && props.multiple) {
    return t('1 project | {n} projects', { n: props.modelValue.length });
  }
  return undefined;
});

const loadingCount = useLoadingCount({ count, state, maxLoadingCount: pageSize });

const items = computed(() => {
  const itemsToLoad = Array(loadingCount.value)
    .fill(null)
    .map((_, id) => ({
      id: id * -1,
      name: '',
      loading: true,
    }));
  return [...props.prependItems, ...state.items.value, ...itemsToLoad];
});

function hasHeader(project, index) {
  if (!props.shouldShowCompanyHeaders || !loaded.value) {
    return false;
  }
  return index === 0 || project.companyId !== items.value[index - 1].companyId;
}

const emptyStateTitle = computed(() => {
  if (searchTerm.value) {
    return t('There are no projects that match your search');
  }
  if (selectedTabId.value === 'recent') {
    return t('No recent projects');
  }
  if (selectedTabId.value === 'starred') {
    return t('No starred projects');
  }
  return t('No projects');
});

const emptyStateDescription = computed(() => {
  if (searchTerm.value) {
    return t('Please try again with a different term');
  }
  if (selectedTabId.value === 'recent') {
    return t('Once you start interacting with projects, they will be shown here');
  }
  if (selectedTabId.value === 'starred') {
    return t('Star any project and you can easily access it from this tab');
  }
  return t('Add some projects and they’ll be displayed here');
});

/**
 * Prevent Vuetify attempting to search for a nullish name and handle null modelValue / modelValue.id
 */
const localModelValue = computed({
  get() {
    if (props.multiple) {
      if (Array.isArray(modelValue.value) && modelValue.value.length) {
        return modelValue.value.map((item) => ({
          id: item.id,
          name: item.name || '',
        }));
      }
      return [];
    }
    if (modelValue.value?.id) {
      return {
        id: modelValue.value.id,
        name: modelValue.value.name ?? '',
      };
    }
    return null;
  },
  set(val) {
    modelValue.value = val;
  },
});

const clearable = computed(() => Boolean(modelValue.value || searchTerm.value));

const shouldShowEmptyState = computed(() => loaded.value && items.value.length === 0);

function isSelected(project) {
  if (props.multiple) {
    return Array.isArray(modelValue.value) ? modelValue.value.some(({ id }) => id === project.id) : false;
  }
  return modelValue.value?.id === project?.id;
}

function selectAll() {
  if (props.mode === 'chip') {
    return;
  }
  requestAnimationFrame(() => {
    autocompleteRef.value.$el.querySelector('input')?.select();
  });
}

function focus() {
  autocompleteRef.value.focus();
}

// Resets search term on focus to show all the autocomplete results.
function resetSearchTerm(isFocused) {
  if (!isFocused) {
    return;
  }
  searchTerm.value = '';
}

defineExpose({
  focus,
});
</script>

<template>
  <VAutocomplete
    ref="autocompleteRef"
    v-bind="mergeProps(VAutocompleteLswPickers, $attrs)"
    v-model="localModelValue"
    :autofocus="autofocus"
    :items="items"
    :disabled="disabled"
    :error="error"
    :errorMessages="errorMessages"
    :multiple="multiple"
    :chips="mode === 'chip'"
    :closableChips="mode === 'chip'"
    noFilter
    returnObject
    itemValue="id"
    itemTitle="name"
    :clearable="clearable"
    :menuProps="{
      ...VAutocompleteLswPickers.menuProps,
      class: {
        'VAutocompleteLswPickersMenu--has-tabs': showTabs,
      },
    }"
    openOnClear
    :loading="!inSync"
    :label="label ?? t('Select a project')"
    @focus="selectAll"
    @update:search="search"
    @update:menu="toggleMenu"
    @update:focused="resetSearchTerm"
  >
    <template #prepend-inner="{ isFocused }">
      <LscIcon v-if="isFocused.value" icon="lsi-search" size="sm" class="text-icon-subtle" />
    </template>
    <template v-if="mode === 'count' && multiple && selectionText" #selection="{ index }">
      <template v-if="index === 0">
        {{ selectionText }}
      </template>
    </template>
    <template #prepend-item>
      <div v-if="showTabs" class="advanced-options">
        <LscTabs v-model="selectedTabId" variant="segmented" justified>
          <LscTab v-for="tab in displayedTabs" :key="tab.id" :dataIdentifier="tab.dataIdentifier" :value="tab.id">
            {{ tab.label }}
          </LscTab>
        </LscTabs>
      </div>
    </template>
    <template #item="{ item: { raw: project }, index, props: { onClick } }">
      <VListItem v-if="project.loading">
        <VListItemTitle class="flex items-center gap-2">
          <div class="flex shrink-0 items-center">
            <LscCheckbox v-if="multiple" disabled tabindex="-1" @click.prevent />
            <LscIcon v-else class="text-icon-subtle" size="sm" icon="lsi-project" />
          </div>
          <LscSkeleton class="h-4 w-full" />
        </VListItemTitle>
      </VListItem>
      <template v-else>
        <VListItem v-if="hasHeader(project, index)" class="v-list-item--header">
          <VListItemTitle class="mb-0 line-clamp-2 whitespace-pre-wrap font-semibold">
            <LscOverflowEllipsis>{{ project.company?.name }}</LscOverflowEllipsis>
          </VListItemTitle>
        </VListItem>
        <VListItem :active="isSelected(project)" :disabled="disableProject(project)" @click="onClick">
          <VListItemTitle class="flex items-center gap-2">
            <div class="flex shrink-0 items-center">
              <LscCheckbox
                v-if="multiple"
                :modelValue="isSelected(project)"
                :disabled="disableProject(project)"
                tabindex="-1"
                @click.prevent
              />
              <LscIcon v-else class="text-icon-subtle" size="sm" icon="lsi-project" />
            </div>
            <div class="flex w-full items-center gap-1 overflow-hidden">
              <LscOverflowEllipsis class="text-body-1">
                {{ project.name }}
              </LscOverflowEllipsis>
              <LscOverflowEllipsis
                v-if="shouldShowCompanyHeaders"
                class="mt-0.5 shrink-0 grow basis-1/4 text-body-2"
                :class="isSelected(project) ? 'text-primary-default' : 'text-subtle'"
              >
                {{ project.company?.name }}
              </LscOverflowEllipsis>
            </div>
            <slot name="itemAppend" :project="project" />
            <div v-if="project.isStarred" class="flex w-7 items-center justify-center">
              <LscIcon class="shrink-0 !text-[color:--project-starred-color]" icon="lsi-favorite" />
            </div>
          </VListItemTitle>
        </VListItem>
      </template>
    </template>
    <template #append-item>
      <LswLoaderTrigger v-model:count="count" :state="state" :step="pageSize" margin="40px" />
    </template>
    <template #append-inner>
      <div v-if="labelTooltip && !disabled" v-LsdTooltip="labelTooltip" class="flex items-center">
        <LscIcon class="pointer-events-auto cursor-pointer text-icon-subtle" size="sm" icon="lsi-tooltip" />
      </div>
    </template>
    <template #no-data>
      <LscEmptyState
        v-if="shouldShowEmptyState"
        size="md"
        class="h-full"
        :title="emptyStateTitle"
        :message="emptyStateDescription"
      >
        <template v-if="!searchTerm" #image>
          <LscSlotSwitch :name="selectedTabId">
            <template #recent>
              <PickersNoProjectsFound />
            </template>
            <template #starred>
              <PickersNoStarredProjectsFound />
            </template>
            <template #all>
              <PickersNoProjectsFound />
            </template>
          </LscSlotSwitch>
        </template>
      </LscEmptyState>
    </template>
  </VAutocomplete>
</template>
