import * as TOKENS from '@teamwork/lightspeed-design-tokens';

export const global = {
  ripple: false,
};

export const VAutocomplete = {
  clearIcon: 'lsi-clear',
  menuIcon: 'lsi-dropdown',
  color: 'primary',
  density: 'compact',
  hideDetails: 'auto',
  variant: 'outlined',
  menuProps: {
    width: 320,
  },
};

export const VBadge = {
  color: 'accent',
  max: 99,
};

export const VChip = {
  closeIcon: 'lsi-close',
  color: 'secondary',
  height: 32,
  variant: 'flat',
};

export const VCombobox = {
  clearIcon: 'lsi-clear',
  menuIcon: 'lsi-dropdown',
  closableChips: true,
  chips: true,
  color: 'primary',
  density: 'compact',
  hideDetails: 'auto',
  multiple: true,
  variant: 'outlined',
  menuProps: {
    maxHeight: 320,
    width: 320,
  },
};

export const VDivider = {
  color: TOKENS.LsdsAColorBorderSeparator,
};

export const VOverlay = {
  noClickAnimation: true,
};

export const VSelect = {
  clearIcon: 'lsi-clear',
  menuIcon: 'lsi-dropdown',
  color: 'primary',
  density: 'compact',
  hideDetails: 'auto',
  transition: false, // Probably just a bug - shouldn't be required
  variant: 'outlined',
  menuProps: {
    width: 320,
    maxHeight: 320,
  },
};

export const VSlider = {
  step: 10,
  color: 'primary',
  hideDetails: 'auto',
  ticks: true,
  thumbLabel: false,
  trackColor: 'secondary',
};

export const VTextarea = {
  color: 'primary',
  density: 'compact',
  hideDetails: 'auto',
  noResize: true,
  rows: 4,
  variant: 'outlined',
};

export const VTextField = {
  color: 'primary',
  density: 'compact',
  hideDetails: 'auto',
  variant: 'outlined',
};

export const VTabs = {
  color: 'primary',
  density: 'default',
  height: 48,
  sliderColor: 'primary',
  VBtn: {
    rounded: false,
  },
};

export const VTab = {
  minWidth: 'auto',
  height: undefined,
  color: 'secondary', // Not working (we hope this would set the inactive color for that tab?)
};

// Needs revisited
export const VProgressLinear = {
  color: '#d8e0ff',
  bgColor: '#f2f5fe',
  bgOpacity: 100,
};
