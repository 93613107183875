import { useListLoader } from '../base/useListLoader';

function responseToItems(response) {
  return response.data.currencies;
}

export function useCurrenciesV3Loader({ params, count, pageSize = 200 }) {
  const { state } = useListLoader({
    url: '/projects/api/v3/currencies.json',
    count,
    pageSize,
    responseToItems,
    params,
  });

  return state;
}
