export const apiTypeToItemType = {
  tasklists: 'tasklist',
  tasks: 'task',
  milestones: 'milestone',
  messages: 'message',
  messagereplies: 'messageReply',
  notebooks: 'notebook',
  files: 'fileVersion', // The backend returns file versions as files, see https://digitalcrew.teamwork.com/app/tasks/24461771.
  forms: 'form',
  timelogs: 'timelog',
  links: 'link',
  comments: 'comment',
  projects: 'project',
};

export const itemTypeToApiType = {
  tasklist: 'tasklists',
  task: 'tasks',
  milestone: 'milestones',
  message: 'messages',
  messageReply: 'messagereplies',
  notebook: 'notebooks',
  fileVersion: 'fileversions',
  file: 'files',
  form: 'forms',
  timelog: 'timelogs',
  link: 'links',
  comment: 'comments',
  project: 'projects',
  user: 'people',
};

export const itemTypeToRestoreEventAction = {
  tasklist: 'undelete',
  task: 'reopened',
  milestone: 'reopened',
  message: 'undeleted', // TODO Ensure consistency with the backend once it starts sending events.
  messageReply: 'undeleted', // TODO Ensure consistency with the backend once it starts sending events.
  notebook: 'undeleted', // TODO Ensure consistency with the backend once it starts sending events.
  fileVersion: 'reopened', // TODO Ensure consistency with the backend once the events are fixed, see https://digitalcrew.teamwork.com/app/tasks/24465808.
  file: 'reopened', // TODO Ensure consistency with the backend once the events are fixed, see https://digitalcrew.teamwork.com/app/tasks/24465808.
  form: 'reopened',
  timelog: 'undeleted',
  link: 'reopened',
  comment: 'undeleted',
  project: 'reopened',
  user: 'undeleted',
};

export const restoreEventActionNames = new Set(Object.values(itemTypeToRestoreEventAction));
