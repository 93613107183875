<script setup>
import { usePeopleV3Loader } from '@/api';
import { useI18n } from '@/util';
import ReportTableCustomReport from '../../table/ReportTableCustomReport.vue';
import { reportBuilderSampleData, reportBuilderSampleDataTotalRow } from '../constants.js';
import { useReportCustomBuilder } from '../useReportCustomBuilder.js';
import { useReportCustomBuilderFilters } from '../useReportCustomBuilderFilters.js';

const { t } = useI18n();
const { currentReport, reportColumns } = useReportCustomBuilder();
const { filterParams } = useReportCustomBuilderFilters();
const count = 10;
const pageSize = 10;
const state = usePeopleV3Loader({
  params: computed(() => ({
    endDate: currentReport.value.endAt.toFormat('yyyy-MM-dd'),
    include: 'companies,teams',
    includeArchivedProjects: filterParams.value.includeArchivedProjects,
    includeClients: filterParams.value.includeClientsCollaborators,
    companyIds: filterParams.value.companyIds,
    ids: filterParams.value.userIds,
    projectIds: filterParams.value.projectIds,
    startDate: currentReport.value.startAt.toFormat('yyyy-MM-dd'),
    teamIds: filterParams.value.teamIds,
    userType: filterParams.value.includeClientsCollaborators ? 'account' : null,
    jobRoleIds: filterParams.value.jobRoleIds,
  })),
  count,
  pageSize,
});

const { items: users } = state;

const usersWithSampleData = computed(() => {
  return users.value?.map((user, index) => ({
    ...reportBuilderSampleData[index],
    user: {
      name: `${user.firstName} ${user.lastName}`,
      ...user,
    },
    company: { name: user.company.name },
  }));
});

const reportTypeColumn = {
  id: 'userName',
  name: t('User'),
  enabled: true,
  draggable: false,
  width: 200,
  align: 'left',
};

const tableColumns = computed({
  get() {
    return [reportTypeColumn, ...reportColumns.value];
  },
  set(_columns) {
    reportColumns.value = _columns.filter((column) => column.id !== 'userName');
  },
});
</script>

<template>
  <LswLoaderState :state="state">
    <template #defaultOrLoading>
      <ReportTableCustomReport
        v-model:columns="tableColumns"
        :items="usersWithSampleData"
        :summaryRowData="reportBuilderSampleDataTotalRow"
        :showTotalRow="currentReport.summary"
      />
    </template>
    <template #blank>
      <LscEmptyState
        class="h-full"
        size="lg"
        :title="t('There are no people that match your criteria')"
        :message="t('Please adjust your date range or filter settings and try again')"
      />
    </template>
  </LswLoaderState>
</template>
